import React, { Component, Fragment } from 'react';
import { Alert } from 'react-bootstrap';
import { FaExclamationCircle } from 'react-icons/fa';
import Loader from '../../global_components/Loader';
import apiRequest from '../../helpers/data';
import { indexOf } from 'lodash';
import { validateText, validateTime, validateDate, validateZip } from '../../helpers/validation';
import _ from 'lodash';
import Initial from './steps/Initial';
import Information from './steps/ConsumerInformation';
import Status from './steps/Status';
import Navigation from '../../global_components/Navigation';
import WhoAreYou from './steps/WhoAreYou';
import DealerInformation from './steps/DealerInformation';
import DealerSearchMethod from './steps/DealerSearchMethod';

export class JobStatus extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			error: null,
			stepIsValid: true,
			searchOnForEasyInquiry: null,
			step: 'initial',
			stepOrder: ['initial', 'consumer_information', 'status'],
			scenarios: {
				initial: ['initial'],
				consumer_adds: ['consumer_information', 'status'],
				dealer_adds: ['dealer_search_method', 'dealer_information', 'status'],
			},
			inputData: {
				status: {
					zip_postal: '',
					invoice: '',
					last_name: '',
				},
			},
			validations: {
				text: validateText,
				time: validateTime,
				date: validateDate,
				zip: validateZip,
			},
			stepMap: {
				initial: {
					component_name: Initial,
					navigation_state: 'jobstatus-initial',
				},
				who_are_you: {
					component_name: WhoAreYou,
					navigation_state: 'previous-only',
				},
				consumer_information: {
					component_name: Information,
					navigation_state: 'default',
					requirements: [
						{ name: 'status-zip_postal', type: 'zip' },
						{ name: 'status-invoice', type: 'text' },
					],
					dependent_function: this.getStatus
				},
				dealer_search_method: {
					component_name: DealerSearchMethod,
					navigation_state: 'previous-only',
				},
				dealer_information: {
					component_name: DealerInformation,
					navigation_state: 'previous-only',
					requirements: [
						{ name: 'status-zip_postal', type: 'zip' },
						{ name: 'status-last_name', type: 'text' },
					],
					dependent_function: this.getStatus
				},
				status: {
					component_name: Status,
					navigation_state: 'jobstatus-final',
				},
				forwardedStatus: {
					component_name: Status,
					navigation_state: 'none',
				},
			},
		};
	}

	setParentState = (stateObject) => {
		Object.keys(stateObject).forEach(key => {
			this.setState({ [key]: stateObject[key] });
		});
	};

	async componentDidMount() {
		document.addEventListener('keydown', this.onEnterKey, false);

		const { forwardParam, business, globalError } = this.props;

		var stepToSet;
		// if user has set to liberalize inquiries (make more easily usable for dealers) then we use different steps
		if (business?.liberalize_job_status_inquiries === 1) {
			this.setState({ stepOrder: ['initial', 'who_are_you'] });
			if (business?.cyberSettings?.show_initial_page) {
				stepToSet = 'initial';
			} else {
				stepToSet = 'who_are_you';
			}
		} else {
			if (business?.cyberSettings?.show_initial_page) {
				stepToSet = 'initial';
			} else {
				stepToSet = 'consumer_information';
			}
		}

		document.title = `${business.client_name} - Job status`;

		// if forwarded
		if (forwardParam) {
			const zipPostal = business.is_canadian ? forwardParam.substring(0,6) : forwardParam.substring(0,5);
			const invoice = business.is_canadian ? forwardParam.substring(6) : forwardParam.substring(5);
			const { client_id } = business;

			const statusResult = await apiRequest({ endpoint: 'v3/publicJobStatus', parameters: { business_id: client_id, zip_code: zipPostal, invoice_number: invoice } });
			const { data: statusInfo } = statusResult;

			if (statusInfo.length === 1) {
				const status = statusInfo[0];
				this.setState({ business, status: status, loading: false, step: 'forwardedStatus' });
			} else {
				globalError('We were unable to find job status given the link provided. Please check with your service provider if you believe this is an error.');
				return false;
			}
		} else {
			this.setState({ loading: false, business, step: stepToSet });
		}
	}

	onEnterKey = (event) => {
		if (event.keyCode === 13) {
			const { step } = this.state;
			const acceptableStepsForKeyedForward = ['consumer_information'];
			if (acceptableStepsForKeyedForward.includes(step)) {
				this.stepForward(false);
			}
		}
	};

	setJobStatus = (status) => {
		console.log(status);
		this.setState({ status });
	};
	setStepOrderAndCurrentStep = (stepOrder, currentStep) => {
		this.setState({
			stepOrder,
			step: currentStep,
		});
	};

	getStatus = async () => {
		return new Promise(async (resolve, reject) => {
			const { business, inputData } = this.state;
			const { client_id } = business;
			const statusResult = await apiRequest({ endpoint: 'v3/publicJobStatus', parameters: { business_id: client_id, zip_code: inputData.status.zip_postal, invoice_number: inputData.status.invoice } });
			const { data: statusInfo } = statusResult;
			if (statusInfo?.length === 1) {
				const status = statusInfo[0];
				const { DateTime } = require('luxon');
				const now = DateTime.local().toLocaleString(DateTime.DATETIME_MED);
				status.lastUpdated = now;
				this.setState({ status });
				resolve();
			} else {
				reject('We could not find any job status information with the information you entered.');
			}
		});
	};

	updateStatus = async () => {
		this.setState({ loading: true });
		const { business, status } = this.state;
		const { client_id } = business;
		const statusResult = await apiRequest({ endpoint: 'v3/publicJobStatus', parameters: { business_id: client_id, zip_code: status?.customer?.zip_code, invoice_number: status?.invoice_number } });
		const { data: statusInfo } = statusResult;
		if (statusInfo?.length === 1) {
			const status = statusInfo[0];
			this.setState({ status: status, loading: false });
		} else {
			this.setState({ loading: false, error: 'We were unable to refresh job status info. Please check with your service provider if you believe this is in error.' });
		}
	};

	renderContentsByStep(step) {
		const { component_name: ComponentName, navigation_state } = this.state.stepMap[step];
		const { business, status, inputData, error } = this.state;
		return (
			<Fragment>
				<ComponentName business={ business } inputData={ inputData } status={ status } inputChange={ this.inputChange } stepForward={ this.stepForward } validated={ this.state.stepIsValid } setJobStatus={ this.setJobStatus } setStepOrderAndCurrentStep={ this.setStepOrderAndCurrentStep } setParentState={ this.setParentState } parentState={ this.state } />
				{ error && <Alert className="mt-3 mb-0" variant="warning"><FaExclamationCircle className="errorIcon"/>&nbsp;&nbsp;{error}</Alert> }
				<Navigation displayMode={navigation_state} stepForward={ this.stepForward } stepBack={ this.stepBack } updateStatus={ this.updateStatus } validated={ this.state.stepIsValid } business={ business } allowStepBack={ true }/>
			</Fragment>
		);
	}

	inputChange = ({ event, otherValue, otherName }) => {
		const { inputData } = this.state;
		var name = event ? event.target.name : false;
		var value = event ? event.target.value : false;
		const useValue = otherValue || value;
		const useName = otherName || name;
		const keyArray = useName.includes('-') ? useName.split('-') : [useName];
		var newObject = {};
		if (keyArray.length === 1) {
			newObject[keyArray[0]] = useValue;
		} else if (keyArray.length === 2) {
			newObject[keyArray[0]] = {};
			newObject[keyArray[0]][keyArray[1]] = useValue;
		}
		const newInputData = _.merge(inputData, newObject);
		this.setState({ inputData: newInputData, stepIsValid: this.validate() });
	};

	stepForward = (andAddStepsFor, skipDependentFunction, waiveStepRequirements) => {
		const { step, stepOrder, business, stepIsValid } = this.state;
		if (!stepIsValid && !waiveStepRequirements) { return false; }
		const initial = this.state.stepOrder;
		var newStepOrder;
		var addedSteps;
		if (andAddStepsFor) {
			console.log(andAddStepsFor);
			const addedStepsKey = `${andAddStepsFor}_adds`;
			addedSteps = this.state.scenarios[addedStepsKey];
			console.log(addedSteps);
			newStepOrder = initial.concat(addedSteps);
			this.setState({ stepOrder: newStepOrder, business: business });
		}
		const relevantStepOrder = newStepOrder || stepOrder;
		const currentStepNumber = indexOf(relevantStepOrder, step);
		const targetStepName = relevantStepOrder[currentStepNumber + 1];
		const dependentFunction = this.state.stepMap[step].dependent_function || false;
		if (dependentFunction && !skipDependentFunction) {
			this.setState({ loading: true });
			dependentFunction().then(() => {
				this.setState({ step: targetStepName, stepIsValid: this.validate(targetStepName), error: null, loading: false });
			})
				.catch((err) => {
					this.setState({ error: err, loading: false });
				});
		} else {
			this.setState({ step: targetStepName, stepIsValid: this.validate(targetStepName), error: null, loading: false });
		}
	};
	stepBack = () => {
		const { step, stepOrder } = this.state;
		const currentStepNumber = indexOf(stepOrder, step);
		const targetStepName = stepOrder[currentStepNumber - 1];
		this.setState({ step: targetStepName, stepIsValid: this.validate(targetStepName), error: null });
	};
	validate = (specifiedStep) => {
		const { inputData, validations, stepMap } = this.state;
		const step = specifiedStep || this.state.step;
		const stepRequirements = stepMap[step]?.requirements;
		if (!stepRequirements) return true;
		var valid = true;
		for (var stepRequirement of stepRequirements) {
			const { name, type } = stepRequirement;
			const keyArray = name.includes('-') ? name.split('-') : [name];
			var valueToCheck;
			if (keyArray.length === 1) {
				valueToCheck = inputData[keyArray[0]];
			} else if (keyArray.length === 2) {
				valueToCheck = inputData[keyArray[0]][keyArray[1]];
			}
			const validation = validations[type];
			if (!validation(valueToCheck)) {
				valid = false;
				break;
			}
		}
		return valid;
	};

	render() {
		const { loading, step } = this.state;
		if (loading) {
			return (
				<Loader />
			);
		} else {
			return (
				<Fragment>
					{ this.renderContentsByStep(step) }
				</Fragment>
			);
		}
	}
}

export default JobStatus;
