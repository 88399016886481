import React, { Component } from 'react';
import { FaCalendarPlus, FaToolbox, FaTools } from 'react-icons/fa';
import { Link } from 'react-router-dom';

export class BrandedHeader extends Component {

	render() {
		const { business, toolName } = this.props;

		function renderOtherToolButtons() {
			if (toolName === 'schedule') {
				return (
					<div className="fixedHeaderLinks">
						{ business.tech_status === 1 && <Link style={ { color: business?.cyberSettings?.foreground_color || '' } } to={ `/track-technician/${ business.client_id }` }><FaToolbox />Track technician</Link> }
						<Link style={ { color: business?.cyberSettings?.foreground_color || '' } } to={ `/check-status/${ business.client_id }` }><FaTools />Check job status</Link>
					</div>
				);
			}
			if (toolName === 'technician') {
				return (
					<div className="fixedHeaderLinks">
						<Link style={ { color: business?.cyberSettings?.foreground_color || '' } } to={ `/schedule/${ business.client_id }` }><FaCalendarPlus />Schedule service</Link>
						<Link style={ { color: business?.cyberSettings?.foreground_color || '' } } to={ `/check-status/${ business.client_id }` }><FaTools />Check job status</Link>
					</div>
				);
			}
			if (toolName === 'job') {
				return (
					<div className="fixedHeaderLinks">
						<Link style={ { color: business?.cyberSettings?.foreground_color || '' } } to={ `/schedule/${ business.client_id }` }><FaCalendarPlus />Schedule service</Link>
						{ business.tech_status === 1 && <Link style={ { color: business?.cyberSettings?.foreground_color || '' } } to={ `/track-technician/${ business.client_id }` }><FaToolbox />Track technician</Link> }
					</div>
				);
			}
		}
		const details = {
			schedule: {
				title: 'Appointment Scheduler'
			},
			technician: {
				title: 'Technician Tracker'
			},
			job: {
				title: 'Job Status Checker'
			},
		};

		return (
			<div className="fixedHeader">
				{ business?.cyberSettings?.back_to_website_url && <a href={ business?.cyberSettings?.back_to_website_url || ''}><img alt="logoImage" src={`https://public-rw-client-assets.s3.us-west-2.amazonaws.com/${business.client_id}/logo_image/${business.cyberSettings.logo_image_name}`}></img></a> }
				{ !business?.cyberSettings?.back_to_website_url && <img alt="logoImage" src={`https://public-rw-client-assets.s3.us-west-2.amazonaws.com/${business.client_id}/logo_image/${business.cyberSettings.logo_image_name}`}></img> }
				<span className="serviceNameInFixedHeader" style={{ color: business?.cyberSettings?.foreground_color || '' }}>{ details[toolName].title }</span>
				{ renderOtherToolButtons() }
			</div>
		);
	}
}

export default BrandedHeader;
