import React, { Component, Fragment } from 'react';
import { Form, FormControl } from 'react-bootstrap';
import Header from '../../../global_components/Header';
import RequiredLabel from '../../../global_components/RequiredLabel';
import { getValueFromInputData } from '../../../helpers/objects';

export class Cancellation extends Component {
	constructor(props) {
		super(props);
		this.nameInput = React.createRef();
	}
	componentDidMount() {
		this.nameInput.current.focus();
	}
	render() {
		const { inputData } = this.props;
		return (
			<Fragment>
				<Header head="Cancel your appointment" subHead="Sorry you can't make your appointment, please let us know who's making the cancellation" />
				<div className="d-flex justify-content-center">
					<Form.Group>
						<Form.Label className="label">First and Last name <RequiredLabel /></Form.Label>
						<FormControl
							size="sm"
							defaultValue={ getValueFromInputData(inputData, 'cancellation-name') }
							onChange={ (event) => { this.props.inputChange({ event }); } }
							name="cancellation-name"
							ref={ this.nameInput }
						/>
					</Form.Group>
				</div>
			</Fragment>
		);
	}
}

export default Cancellation;
