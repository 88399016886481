import React, { Component, Fragment } from 'react';
import { Col, Form } from 'react-bootstrap';
import Header from '../../../global_components/Header';
import RequiredLabel from '../../../global_components/RequiredLabel';
import { getValueFromInputData } from '../../../helpers/objects';

export class ExtendedWarranty extends Component {
	constructor(props) {
		super(props);
		this.providerInput = React.createRef();
	}
	componentDidMount() {
		this.providerInput.current.focus();
	}

	render() {
		const { inputData } = this.props;
		return (
			<Fragment>
				<Header head="Extended warranty provider" subHead="Which warranty provider is covering this service?" />
				<div>
					<Form>
						<Form.Row>
							<Col>
								<Form.Label className="label">Provider <RequiredLabel /></Form.Label>
								<Form.Control ref={ this.providerInput } size="sm" defaultValue={ getValueFromInputData(inputData, 'extended_warranty-provider') } name="extended_warranty-provider" onChange={ (event) => { this.props.inputChange({ event }); } } maxLength="60"/>
							</Col>
						</Form.Row>
						<Form.Row>
							<Col>
								<Form.Label className="label">Contract number <RequiredLabel /></Form.Label>
								<Form.Control size="sm" defaultValue={ getValueFromInputData(inputData, 'extended_warranty-contract_number') } name="extended_warranty-contract_number" onChange={ (event) => { this.props.inputChange({ event }); } } maxLength="60"/>
							</Col>
							<Col>
								<Form.Label className="label">Purchase order number</Form.Label>
								<Form.Control size="sm" defaultValue={ getValueFromInputData(inputData, 'extended_warranty-purchase_order_number') } name="extended_warranty-purchase_order_number" onChange={ (event) => { this.props.inputChange({ event }); } } maxLength="60"/>
							</Col>
						</Form.Row>
					</Form>
				</div>
			</Fragment>
		);
	}
}

export default ExtendedWarranty;
