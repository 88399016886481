import React, { Component } from 'react';
import { MdKeyboardArrowRight, MdCheck, MdCancel, MdEditCalendar } from 'react-icons/md';

export class Navigation extends Component {
	render() {
		const { validated, displayMode, business, allowStepBack } = this.props;
		switch (displayMode) {
		default:
			return ('');
		case 'default':
			return (
				<div className="w-100 navContainer">
					{ allowStepBack &&
					<span className="navButton leftButton" onClick={ this.props.stepBack }>
						Back
					</span>
					}
					<span className={`navButton rightButton ${!validated && 'rightButtonDisabled'}`} onClick={ () => { this.props.stepForward(false); } } style={{ backgroundColor: business?.cyberSettings?.foreground_color || '#007bff' }}>
						Next
						<MdKeyboardArrowRight />
					</span>
				</div>
			);
		case 'previous-only':
			if (allowStepBack) {
				return (
					<div className="w-100 navContainer">
						<span className="navButton leftButton" onClick={ this.props.stepBack }>
							Back
						</span>
					</div>
				);
			} else {
				return <div></div>;
			}
		case 'none':
			return ('');
		case 'schedule-final':
			return (
				<div className="w-100 navContainer">
					{ allowStepBack &&
					<span className="navButton leftButton" onClick={ this.props.stepBack }>
						Back
					</span>
					}
					<span className={`navButton rightButton ${!validated && 'rightButtonDisabled'}`} onClick={ () => { this.props.stepForward(false); } } style={{ backgroundColor: business?.cyberSettings?.foreground_color || '#007bff' }}>
						Schedule
						&nbsp;
						<MdCheck />
					</span>
				</div>
			);
		case 'confirm-final':
			return (
				<div className="w-100 navContainer">
					{ allowStepBack &&
					<span className="navButton leftButton" onClick={ this.props.stepBack }>
						Back
					</span>
					}
					<span className={`navButton rightButton ${!validated && 'rightButtonDisabled'}`} onClick={ () => { this.props.stepForward(false); } } style={{ backgroundColor: '#198754' }}>
						Confirm
						&nbsp;
						<span className="confirmIconTranslate">
							<MdCheck />
						</span>
					</span>
				</div>
			);
		case 'cancel-final':
			return (
				<div className="w-100 navContainer">
					{ allowStepBack &&
					<span className="navButton leftButton" onClick={ this.props.stepBack }>
						Back
					</span>
					}
					<span className={`navButton rightButton ${!validated && 'rightButtonDisabled'}`} onClick={ () => { this.props.stepForward(false); } } style={{ backgroundColor: '#dc3644' }}>
						Cancel
						&nbsp;
						<span className="cancelIconTranslate">
							<MdCancel />
						</span>
					</span>
				</div>
			);
		case 'reschedule-final':
			return (
				<div className="w-100 navContainer">
					{ allowStepBack &&
					<span className="navButton leftButton" onClick={ this.props.stepBack }>
						Back
					</span>
					}
					<span className={`navButton rightButton ${!validated && 'rightButtonDisabled'}`} onClick={ () => { this.props.stepForward(false); } } style={{ backgroundColor: '#007bff' }}>
						Reschedule
						&nbsp;
						<span className="cancelIconTranslate">
							<MdEditCalendar />
						</span>
					</span>
				</div>
			);
		case 'invite-initial':
			return (
				<div className="w-100 navContainer">
					<span className={`navButton rightButton ${!validated && 'rightButtonDisabled'}`} onClick={ () => { this.props.stepForward(false); } } style={{ backgroundColor: business?.cyberSettings?.foreground_color || '#007bff' }}>
						Schedule
						&nbsp;
						<span className="cancelIconTranslate">
							<MdKeyboardArrowRight />
						</span>
					</span>
				</div>
			);
		case 'invite-final':
			return (
				<div className="w-100 navContainer">
					<span className="navButton leftButton" onClick={ this.props.stepBack }>
						Back
					</span>
					<span className={`navButton rightButton ${!validated && 'rightButtonDisabled'}`} onClick={ () => { this.props.stepForward(false); } } style={{ backgroundColor: business?.cyberSettings?.foreground_color || '#007bff' }}>
						Schedule
						&nbsp;
						<span className="cancelIconTranslate">
							<MdCheck />
						</span>
					</span>
				</div>
			);
		case 'jobstatus-initial':
			return (
				<div className="w-100 navContainer">
					<span className={`navButton rightButton ${!validated && 'rightButtonDisabled'}`} onClick={ () => { this.props.stepForward(false); } } style={{ backgroundColor: business?.cyberSettings?.foreground_color || '#007bff' }}>
						Check job status
						&nbsp;
						<span className="cancelIconTranslate">
							<MdKeyboardArrowRight />
						</span>
					</span>
				</div>
			);
		case 'jobstatus-final':
			return (
				<div className="w-100 navContainer">
					<span className="navButton leftButton" onClick={ this.props.stepBack }>
						Back
					</span>
					<span className={`navButton rightButton ${!validated && 'rightButtonDisabled'}`} onClick={ () => { this.props.updateStatus(); } } style={{ backgroundColor: business?.cyberSettings?.foreground_color || '#007bff' }}>
						Check
						&nbsp;
						<span className="cancelIconTranslate">
							<MdCheck />
						</span>
					</span>
				</div>
			);
		case 'tracktechnician-initial':
			return (
				<div className="w-100 navContainer">
					<span className={`navButton rightButton ${!validated && 'rightButtonDisabled'}`} onClick={ () => { this.props.stepForward(false); } } style={{ backgroundColor: business?.cyberSettings?.foreground_color || '#007bff' }}>
						Track technician
						&nbsp;
						<span className="cancelIconTranslate">
							<MdKeyboardArrowRight />
						</span>
					</span>
				</div>
			);
		case 'tracktechnician-final':
			return (
				<div className="w-100 navContainer">
					<span className="navButton leftButton" onClick={ this.props.stepBack }>
						Back
					</span>
					<span className={`navButton rightButton ${!validated && 'rightButtonDisabled'}`} onClick={ () => { this.props.updateTracking(); } } style={{ backgroundColor: business?.cyberSettings?.foreground_color || '#007bff' }}>
						Check
						&nbsp;
						<span className="cancelIconTranslate">
							<MdCheck />
						</span>
					</span>
				</div>
			);

		}
	}
}
export default Navigation;
