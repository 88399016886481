import React, { Component } from 'react';
import { formatPhoneReadable } from '../../../helpers/formatting';

export class RecallWarning extends Component {
	render() {
		return (
			<div>
				<p>We&apos;re sorry you&apos;re having trouble. We work very hard to assure that every problem is fixed -- correctly and completely -- the first time.</p>
				<p>As part of our effort to assure quality, we need to review each recall request before sending the technician back out.</p>
				<p>Please, either call the office at { formatPhoneReadable(this.props.business.client_phone) } or email us at { this.props.business.client_email } with a concise description of what you&apos;re encountering. We promise a prompt response.</p>
			</div>
		);
	}
}

export default RecallWarning;
