import React, { Component, Fragment } from 'react';
import { Button, Col, Form } from 'react-bootstrap';
import Header from '../../../global_components/Header';
import Flatpickr from 'react-flatpickr';
import { getValueFromInputData } from '../../../helpers/objects';
import RequiredLabel from '../../../global_components/RequiredLabel';
import { FaAlignLeft, FaList } from 'react-icons/fa';

export class MachineInformation extends Component {
	constructor(props) {
		super(props);
		this.makeSelect = React.createRef();
		this.state = {
			showExtraNotes: false,
		};
	}
	componentDidMount() {
		this.makeSelect.current.focus();
	}
	renderMakes = () => {
		const { business } = this.props;
		const { makes } = business;
		return makes.map((make, i) => {
			return <option value={ make.name } key={ i }>{ make.name }</option>;
		});
	};
	renderTypes = () => {
		const { business } = this.props;
		const { types } = business;
		return types.map((type, i) => {
			return <option value={ type.name } key={ i }>{ type.name }</option>;
		});
	};
	renderDealers = () => {
		const { business } = this.props;
		const { dealers } = business;
		return dealers.map((dealer, i) => {
			return <option value={ dealer.t_text } key={ i }>{ dealer.t_text }</option>;
		});
	};
	usableDateFromFlatpickr(date) {
		const { DateTime: dt } = require('luxon');
		const dateTime = dt.fromISO(date.toISOString());
		return dateTime.toISODate();
	}
	render() {
		const { inputData, business, showOtherTextBoxes, scenario } = this.props;
		const { showExtraNotes } = this.state;
		return (
			<Fragment>
				<Header head="Machine information" subHead="What will we work on?" />
				<div>
					<Form>
						<Form.Row className="formRow">
							<Col>
								<Form.Label className="label">Manufacturer <RequiredLabel /></Form.Label>
								{ !showOtherTextBoxes['machine_make'] &&
								<Form.Control ref={ this.makeSelect } size="sm" as="select" defaultValue={ getValueFromInputData(inputData, 'machine-make') } name="machine-make" onChange={ (event) => { this.props.inputChange({ event }); } }>
									<option disabled value="default">Select</option>
									{ this.renderMakes() }
									<option disabled value="separator">- -</option>
									<option value="other">Other manufacturer</option>
								</Form.Control>
								}
								{ !!showOtherTextBoxes['machine_make'] &&
								<Fragment>
									<Form.Control size="sm" defaultValue={ getValueFromInputData(inputData, 'machine-make') } name="machine-make" onChange={ (event) => { this.props.inputChange({ event }); } } />
									<Button variant="secondary" size="sm" className="backToListButton mt-1" onClick={ () => this.props.toggleOtherTextBoxes('machine-make') }><FaList />Back to &apos;manufacturers&apos; list</Button>
								</Fragment>
								}
							</Col>
							<Col>
								<Form.Label className="label">Type <RequiredLabel /></Form.Label>
								{ !showOtherTextBoxes['machine_type'] &&
								<Form.Control size="sm" as="select" defaultValue={ getValueFromInputData(inputData, 'machine-type') } name="machine-type" onChange={ (event) => { this.props.inputChange({ event }); } }>
									<option disabled value="default">Select</option>
									{ this.renderTypes() }
									<option disabled value="separator">- -</option>
									<option value="other">Other type</option>
								</Form.Control>
								}
								{ !!showOtherTextBoxes['machine_type'] &&
								<Fragment>
									<Form.Control size="sm" defaultValue={ getValueFromInputData(inputData, 'machine-type') } name="machine-type" onChange={ (event) => { this.props.inputChange({ event }); } } />
									<Button variant="secondary" size="sm" className="backToListButton mt-1" onClick={ () => this.props.toggleOtherTextBoxes('machine-type') }><FaList />Back to &apos;types&apos; list</Button>
								</Fragment>
								}
							</Col>
						</Form.Row>
						<Form.Row className="formRow">
							<Col>
								<Form.Label className="label">Model number { (business.require_model_serial === 1 || scenario === 'extended_warranty' || scenario === 'original_warranty') && <RequiredLabel /> }</Form.Label>
								<Form.Control size="sm" defaultValue={ getValueFromInputData(inputData, 'machine-model_number') } name="machine-model_number" onChange={ (event) => { this.props.inputChange({ event }); } } />
							</Col>
							<Col>
								<Form.Label className="label">Serial number { (business.require_model_serial === 1 || scenario === 'extended_warranty' || scenario === 'original_warranty') && <RequiredLabel /> }</Form.Label>
								<Form.Control size="sm" defaultValue={ getValueFromInputData(inputData, 'machine-serial_number') } name="machine-serial_number" onChange={ (event) => { this.props.inputChange({ event }); } } />
							</Col>
						</Form.Row>
						<Form.Row className="formRow">
							<Col>
								<Form.Label className="label">Dealer { (scenario === 'extended_warranty' || scenario === 'original_warranty') && <RequiredLabel />}</Form.Label>
								{ !showOtherTextBoxes['machine_dealer'] &&
								<Form.Control size="sm" as="select" defaultValue={ getValueFromInputData(inputData, 'machine-dealer') } name="machine-dealer" onChange={ (event) => { this.props.inputChange({ event }); } }>
									<option disabled value="default">Select</option>
									{ this.renderDealers() }
									<option disabled value="separator">- -</option>
									<option value="other">Other dealer</option>
								</Form.Control>
								}
								{ !!showOtherTextBoxes['machine_dealer'] &&
								<Fragment>
									<Form.Control size="sm" defaultValue={ getValueFromInputData(inputData, 'machine-dealer') } name="machine-dealer" onChange={ (event) => { this.props.inputChange({ event }); } } />
									<Button variant="secondary" size="sm" className="backToListButton mt-1" onClick={ () => this.props.toggleOtherTextBoxes('machine-dealer') }><FaList />Back to &apos;dealers list&apos;</Button>
								</Fragment>
								}
							</Col>
							<Col>
								<Form.Label className="label">Purchase date { (scenario === 'extended_warranty' || scenario === 'original_warranty') && <RequiredLabel />}</Form.Label>
								<Flatpickr
									placeholder="Click to select"
									className="form-control form-control-sm"
									onChange={ (date) => { this.props.inputChange({ otherValue: this.usableDateFromFlatpickr(date[0]), otherName: 'machine-purchase_date' }); } }
									options={
										{
											maxDate: new Date(),
											dateFormat: 'm-d-Y',
										}
									}
								/>
							</Col>
						</Form.Row>
						<Form.Row className="formRow">
							<Col>
								<Form.Label className="label">Problem description <RequiredLabel /></Form.Label>
								<Form.Control rows="1" className="problemDescription" size="sm" maxLength="100" defaultValue={ getValueFromInputData(inputData, 'machine-problem_description') } name="machine-problem_description" onChange={ (event) => { this.props.inputChange({ event }); } } as="textarea" />
							</Col>
						</Form.Row>
						{
							!showExtraNotes && <div onClick={ () => { this.setState({ showExtraNotes: !showExtraNotes }); } } className='extra-notes-button'><FaAlignLeft />&nbsp;&nbsp;Click to add extra notes</div>
						}

						{ showExtraNotes &&
							<Form.Row className="formRow">
								<Col>
									<Form.Label className="label">Extra notes</Form.Label>
									<Form.Control size="sm" maxLength="250" defaultValue={ getValueFromInputData(inputData, 'machine-extra_notes') } name="machine-extra_notes" onChange={ (event) => { this.props.inputChange({ event }); } } as="textarea" />
								</Col>
							</Form.Row>
						}
					</Form>
				</div>
			</Fragment>
		);
	}
}

export default MachineInformation;
