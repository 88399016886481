import React, { Component, Fragment } from 'react';
import { Alert } from 'react-bootstrap';
import { FaExclamationCircle } from 'react-icons/fa';
import Loader from '../../global_components/Loader';
import apiRequest from '../../helpers/data';
import { getZipPostalFromInvite } from '../../helpers/objects';
import Initial from './steps/Initial';
import { indexOf } from 'lodash';
import { validateText, validateTime, validateDate } from '../../helpers/validation';
import _ from 'lodash';
import Schedule from './steps/Schedule';
import Scheduled from './steps/Scheduled';
import Navigation from '../../global_components/Navigation';
import CustomTerms from './steps/CustomTerms';

export class Invite extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			error: null,
			stepIsValid: true,
			step: 'initial',
			stepOrder: ['initial', 'schedule', 'scheduled'],
			inputData: {
				invite: {
					date: '',
					time: 'default',
					name: '',
				},
			},
			validations: {
				text: validateText,
				time: validateTime,
				date: validateDate,
			},
			stepMap: {
				initial: {
					component_name: Initial,
					navigation_state: 'invite-initial',
				},
				schedule: {
					component_name: Schedule,
					navigation_state: 'invite-final',
					requirements: [
						{ name: 'invite-date', type: 'date' },
						{ name: 'invite-name', type: 'text' },
						{ name: 'invite-time', type: 'text' },
					],
					dependent_function: this.processScheduleOnInvite,
				},
				custom_terms: {
					component_name: CustomTerms,
					navigation_state: 'default',
					requirements:
						[
							{ name: 'invite-terms_agreed', type: 'bool' },
						]
				},
				scheduled: {
					component_name: Scheduled,
					navigation_state: 'none',
				},
			},
		};
	}

	componentDidMount() {
		this.getInvite();
	}

	getInvite = async () => {
		const { forwardParam: linkId, business, globalError } = this.props;

		if (business.cyberSettings.show_custom_terms) {
			this.setState({ stepOrder: ['initial', 'schedule', 'scheduled'] });
		}
		const { stepMap } = this.state;
		if (!business.offer_time_frames) {
			stepMap.schedule.requirements.splice(2,1);
		}
		let [frames_response, invite_response] = await Promise.all([
			apiRequest({ endpoint: 'v3/publicTimeFrames', parameters: { business_id: business.client_id } }),
			apiRequest({ endpoint: 'v3/publicDraftJobData', parameters: { business_id: business.client_id, link_id: linkId } }),
		]);
		const { data: frames } = frames_response;
		business.frames = frames;
		document.title = `${business.client_name} - Schedule appointment`;

		if (invite_response.data && invite_response.data.length === 1) { // if invite request exists
			const invite = invite_response.data[0];
			const whenPostedDate = new Date(invite.when_posted.substring(0,10));
			const todaysDate = new Date();
			const daysElapsedSinceInvite = (todaysDate - whenPostedDate) / 1000 / 60 / 60 / 24;
			if ((invite.invite_expired === 1) || (daysElapsedSinceInvite > 3) || invite.when_responded) { // if the invite expired flag is set to true inside database
				globalError('We are unable to let you schedule this appointment, as the invite has expired or already been used.');
				return false;
			}

			const isCanadian = business.is_canadian === 0 ? false : true;
			const zipPostal = getZipPostalFromInvite(invite, isCanadian);
			const availability = await apiRequest({ endpoint: 'v3/publicAvailabilityByZip', parameters: { business_id: business.client_id, zip_code: zipPostal, department: invite?.department, omit_zones_list: business.cyberSettings?.omit_zones ? business.cyberSettings?.omit_zones_list : '' } });
			if (availability?.data?.length > 0) {
				var availableDates = [];
				var now = Date.now();
				for (let i = 0; i < availability['data'].length; i++) {
					var date = Date.parse(availability['data'][i]['AvlblDt']);
					if ((availability['data'][i]['SlotsAvailable'] > 0) && (date >= now)) {
						availableDates.push(availability['data'][i]['AvlblDt'].substring(0,10));
					}
				}
				business.availability_for_zip = availableDates;
				this.setState({ invite: invite, business: business, loading: false, stepMap });
			} else { // if no dates for reschedule are available
				globalError(`We should be able to let you schedule your appointment, but we don't show ${ business.client_name } as having any availability in your area. Please contact them directly to schedule your service.`);
				return false;
			}
		} else {
			globalError('We could not find any records given the information provided. This could be because your invite link expired or invalid information was entered.');
			return false;
		}
	};

	processScheduleOnInvite = () => {
		return new Promise(async (resolve) => {
			const { business, invite, inputData } = this.state;
			const { link_id } = invite;
			const { client_id } = business;
			const scheduleResponse = await apiRequest({ endpoint: 'v3/updatePublicDraftJobData', parameters: { business_id: client_id, link_id: link_id, person_who_connected: inputData.invite.name, new_appointment: inputData.invite.date, timeframe: inputData.invite.time === 'default' ? '' : inputData.invite.time } });
			if (business?.cyberSettings?.url_after_schedule) {
				window.location.replace(business.cyberSettings.url_after_schedule);
			}
			resolve(scheduleResponse);
		});
	};

	renderContentsByStep(step) {
		const { error } = this.state;
		const { component_name: ComponentName, navigation_state } = this.state.stepMap[step];
		const { business, invite, inputData } = this.state;
		return (
			<Fragment>
				<ComponentName business={ business } inputData={ inputData } invite={ invite } inputChange={ this.inputChange } />
				{ error && <Alert className="mx-3" variant="warning"><FaExclamationCircle className="errorIcon"/>&nbsp;&nbsp;{error}</Alert> }
				<Navigation displayMode={navigation_state} stepForward={ this.stepForward } stepBack={ this.stepBack } validated={ this.state.stepIsValid } business={ business }/>
			</Fragment>
		);
	}

	inputChange = ({ event, otherValue, otherName }) => {
		const { inputData } = this.state;
		var name = event ? event.target.name : false;
		var value = event ? event.target.value : false;
		const useValue = otherValue || value;
		const useName = otherName || name;
		const keyArray = useName.includes('-') ? useName.split('-') : [useName];
		var newObject = {};
		if (keyArray.length === 1) {
			newObject[keyArray[0]] = useValue;
		} else if (keyArray.length === 2) {
			newObject[keyArray[0]] = {};
			newObject[keyArray[0]][keyArray[1]] = useValue;
		}
		const newInputData = _.merge(inputData, newObject);
		this.setState({ inputData: newInputData, stepIsValid: this.validate() });
	};

	stepForward = () => {
		const { step, stepOrder, stepIsValid } = this.state;
		if (!stepIsValid) { return false; }
		const currentStepNumber = indexOf(stepOrder, step);
		const targetStepName = stepOrder[currentStepNumber + 1];
		const dependentFunction = this.state.stepMap[step].dependent_function || false;
		if (dependentFunction) {
			this.setState({ loading: true });
			dependentFunction().then(() => {
				this.setState({ step: targetStepName, stepIsValid: this.validate(targetStepName), error: null, loading: false });
			})
				.catch((err) => {
					this.setState({ error: err, loading: false });
				});
		} else {
			this.setState({ step: targetStepName, stepIsValid: this.validate(targetStepName), error: null, loading: false });
		}
	};
	stepBack = () => {
		const { step, stepOrder } = this.state;
		const currentStepNumber = indexOf(stepOrder, step);
		const targetStepName = stepOrder[currentStepNumber - 1];
		this.setState({ step: targetStepName, stepIsValid: this.validate(targetStepName), error: null });
	};

	validate = (specifiedStep) => {
		const { inputData, validations, stepMap } = this.state;
		const step = specifiedStep || this.state.step;
		const stepRequirements = stepMap[step].requirements;
		if (!stepRequirements) return true;
		var valid = true;
		for (var stepRequirement of stepRequirements) {
			const { name, type } = stepRequirement;
			const keyArray = name.includes('-') ? name.split('-') : [name];
			var valueToCheck;
			if (keyArray.length === 1) {
				valueToCheck = inputData[keyArray[0]];
			} else if (keyArray.length === 2) {
				valueToCheck = inputData[keyArray[0]][keyArray[1]];
			}
			const validation = validations[type];
			if (!validation(valueToCheck)) {
				valid = false;
				break;
			}
		}
		return valid;
	};

	render() {
		const { loading, step } = this.state;
		if (loading) {
			return (
				<Loader />
			);
		} else {
			return (
				<Fragment>
					{ this.renderContentsByStep(step) }
				</Fragment>
			);
		}
	}
}

export default Invite;
