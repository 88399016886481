import React, { Component, Fragment } from 'react';
import Header from '../../../global_components/Header';
import apiRequest from '../../../helpers/data';
import PrettyBox from '../../../global_components/PrettyBox';
import { FaHome, FaUserCircle } from 'react-icons/fa';

export class DealerSearchMethod extends Component {
	state = {
		statusResult: null
	};

	onInputChange = () => {
		const { business, inputData, validated } = this.props;
		this.setState({ statusResult: null });
		console.log(inputData, business);
		if (validated) {
			setTimeout(async () => {
				const statusResult = await apiRequest({ endpoint: 'v3/publicJobStatus', parameters: { business_id: business.client_id, zip_code: inputData.status.zip_postal, last_name: inputData.status.last_name, no_charrge: true } });
				this.setState({ statusResult });
			}, 2000);
		}
	};

	render() {
		const { business } = this.props;
		return (
			<Fragment>
				<Header head="What information do you have available to search on?" />
				<div className="grid-2-col">
					<PrettyBox action={ () => {
						this.props.setParentState({ searchOnForEasyInquiry: 'last_name' });
						this.props.stepForward();
					} } head="Last name" Icon={ FaUserCircle } color={ business?.cyberSettings?.foreground_color } />
					<PrettyBox action={ () => {
						this.props.setParentState({ searchOnForEasyInquiry: 'street_address' });
						this.props.stepForward();
					} } head="Street number" Icon={ FaHome } color={ business?.cyberSettings?.foreground_color } />
				</div>
			</Fragment>

		);
	}
}

export default DealerSearchMethod;
