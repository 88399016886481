import React, { Component } from 'react';

export class BadgeThing extends Component {
	render() {
		const { content, label } = this.props;
		return (
			<div className="badgeThing">
				<span className="badgeThingLabel">{ label }</span><br></br>
				<span className="badgeThingContent">{ content }</span>
			</div>
		);
	}
}

export default BadgeThing;
