import React, { Component } from 'react';
import { FaExclamationCircle } from 'react-icons/fa';

export class NoID extends Component {
	render() {
		return (
			<div className="justify-content-center">
				<FaExclamationCircle className="errorIcon"/>&nbsp;
				Please enter a business ID in the URL to use CyberOffice services.
			</div>
		);
	}
}

export default NoID;
