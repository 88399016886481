import React, { Component } from 'react';
import { TiArrowBack } from 'react-icons/ti';

export class BrandedFooter extends Component {
	render() {
		const { business } = this.props;
		return (
			<a className="fixedFooter" style={ { color: business?.cyberSettings?.foreground_color || '' } } href={ business?.cyberSettings?.back_to_website_url || ''} >
				<TiArrowBack />Back to our website
			</a>
		);
	}
}

export default BrandedFooter;
