import React, { Component } from 'react';

export class Loader extends Component {
	render() {
		return (
			<div className="d-flex justify-content-center py-5 mainLoader">
				<div className="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
			</div>
		);
	}
}

export default Loader;
