import React, { Component, Fragment } from 'react';
import Header from '../../../global_components/Header';

export class Initial extends Component {
	render() {
		const { invite } = this.props;
		return (
			<Fragment>
				<Header head="Schedule your appointment" subHead={'We received a request to schedule with the below details, click to schedule'} />
				<div>
					<b>Location: </b><br />
					{ invite.location.name }<br />
					{ invite.location.address }<br />
					{ invite.location.address2 }<br />
					<b>To be serviced: </b><br />
					{ invite.machine.make }<br />
					{ invite.machine.type }<br />
				</div>
			</Fragment>
		);
	}
}

export default Initial;
