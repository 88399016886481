export function formatTimeFrameReadable(timeFrame, returnType) {
	var text;
	var description;
	var arr;
	var ampm;

	if (!timeFrame) {
		return 'time unknown';
	}

	if (timeFrame === 'default') {
		return '';
	}

	if (timeFrame.includes('-') && !(timeFrame.match(/[a-z]/i))) {
		arr = timeFrame.split('-');
		const fromArr = arr[0].split(':');
		const toArr = arr[1].split(':');
		const fromHour = parseInt(fromArr[0]);
		let fromMin = parseInt(fromArr[1]);
		const toHour = parseInt(toArr[0]);
		let toMin = parseInt(toArr[1]);
		const avgHour = (toHour + fromHour) / 2;
		ampm = ( (avgHour < 7) || fromHour >= 11 ) ? 'pm' : 'am';
		if (toHour === 12) {
			ampm = 'pm';
		}
		fromMin = fromMin ? fromMin : '00';
		toMin = toMin ? toMin : '00';

		text = `${fromHour}:${fromMin} - ${toHour}:${toMin}${ampm}`;
		description = `between ${fromHour}:${fromMin} and ${toHour}:${toMin}${ampm}`;
		// text = fromMin && toMin ? `${fromHour}:${fromMin} - ${toHour}:${toMin}${ampm}` : `${fromHour}:00 - ${toHour}:00${ampm}`
		// description = fromMin && toMin ? `between ${fromHour}:${fromMin} and ${toHour}:${toMin}${ampm}` : `between ${fromHour}:00 and ${toHour}:00${ampm}`
	} else {
		switch (timeFrame) {
		case 'Any-Tm':
			text = 'Any time';
			description = 'at any time';
			break;
		case 'AM':
			text = 'Morning';
			description = 'in the morning';
			break;
		case 'PM':
			text = 'Afternoon';
			description = 'in the afternoon';
			break;
		case '!':
			text = 'Any time';
			description = 'at any time';
			break;
		default:
			text = timeFrame;
			description = timeFrame;
		}
	}
	if (returnType === 'text') {
		return text;
	} else if (returnType === 'description') {
		return description;
	}
}

export function formatDateForSummary(date) {
	const { DateTime: dt } = require('luxon');
	const newDate = dt.fromISO(date).toLocaleString({ weekday: 'long', month: 'long', day: '2-digit' }); //=> 'Thursday, April 20'
	return newDate;
}

export function removeZoneFromAddress(address) {
	if (!address.includes('[') || !address.includes(']')) {
		return address;
	} else {
		return address.substring(0, address.indexOf('['));
	}
}

export function formatPhoneReadable (phone) {
	if (phone === null) {
		return false;
	}
	var strippedPhone = phone.replace(/\D/g, '');
	if (strippedPhone.substring(0,1) === 1) {
		if (strippedPhone.length > 1 && strippedPhone.length < 4) {
			return strippedPhone.substring(0,1) + '-' + strippedPhone.substring(1);
		} else if (strippedPhone.length === 4) {
			return strippedPhone.substring(0,1) + '-' + strippedPhone.substring(1,4);
		} else if (strippedPhone.length > 4 && strippedPhone.length < 8) {
			return strippedPhone.substring(0,1) + '-' + strippedPhone.substring(1,4) + '-' + strippedPhone.substring(4);
		} else if (strippedPhone.length > 7) {
			return strippedPhone.substring(0,1) + '-' + strippedPhone.substring(1,4) + '-' + strippedPhone.substring(4,7) + '-' + strippedPhone.substring(7);
		} else {
			return strippedPhone;
		}
	} else {
		if (strippedPhone.length > 1 && strippedPhone.length < 3) {
			return '(' + strippedPhone.substring(0);
		} else if (strippedPhone.length > 2 && strippedPhone.length < 6) {
			return '(' + strippedPhone.substring(0,3) + ') ' + strippedPhone.substring(3);
		} else if (strippedPhone.length > 5) {
			return '(' + strippedPhone.substring(0,3) + ') ' + strippedPhone.substring(3,6) + '-' + strippedPhone.substring(6);
		} else {
			return strippedPhone;
		}
	}
}

export function formatPhoneForDatabase(phone) {
	if (!phone || phone === '') {
		return null;
	}
	const strippedNumbers = phone.replace(/[^0-9]/g, '');
	return `${strippedNumbers.substring(0, 3)}-${strippedNumbers.substring(3, 6)}-${strippedNumbers.substring(6)}`;
}