import { Component, Fragment } from 'react';
import apiRequest from '../../helpers/data';
import { modifyStepMapAndStepOrder, parseAppointmentParamsFromState, scenarioStringToNumber } from '../../helpers/objects';
import React from 'react';
import _ from 'lodash';
import { Alert } from 'react-bootstrap';
import PaymentStructure from './steps/PaymentStructure';
import { indexOf } from 'lodash';
import ContinuationWarning from './steps/ContinuationWarning';
import RecallWarning from './steps/RecallWarning';
import ZipPostal from './steps/ZipPostal';
import Loader from '../../global_components/Loader';
import DateTime from './steps/DateTime';
import CustomerInformation from './steps/CustomerInformation';
import MachineInformation from './steps/MachineInformation';
import PaymentType from './steps/PaymentType';
import Finalize from './steps/Finalize';
import Summarize from './steps/Summarize';
import ExtendedWarranty from './steps/ExtendedWarranty';
import ThirdPartyPayer from './steps/ThirdPartyPayer';
import { validateText, validateEmail, validateDate, validateTime, validateAddress, validateZip, validatePhone, validateBoolForTrue, validateCustomerName } from '../../helpers/validation';
import NoDirectSchedule from './steps/NoDirectSchedule';
import { FaExclamationCircle } from 'react-icons/fa';
import NoID from './steps/NoID';
import OfferVirtualMeeting from './steps/OfferVirtualMeeting';
import CustomTerms from './steps/CustomTerms';
import Navigation from '../../global_components/Navigation';
import { DateTime as LuxonDate } from 'luxon';
import { inputData, scenarios } from '../../assets/objects';
import { formatPhoneReadable } from '../../helpers/formatting';

export class Schedule extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			error: null,
			stepIsValid: true,
			step: 'payment_structure',
			stepOrder: ['payment_structure'],
			scenario: 'regular_schedule',
			scenarios: scenarios,
			scenarioAsNumber: null,
			inputData: inputData,
			validations: {
				customerName: validateCustomerName,
				text: validateText,
				email: validateEmail,
				date: validateDate,
				time: validateTime,
				address: validateAddress,
				zip: validateZip,
				phone: validatePhone,
				bool: validateBoolForTrue,
			},
			showOtherTextBoxes: {
				machine_make: false,
				machine_type: false,
				machine_dealer: false,
			},
			department: null,
			stepMap: {
				offer_virtual_appointment: {
					component_name: OfferVirtualMeeting,
					navigation_state: 'none'
				},
				payment_structure: {
					component_name: PaymentStructure,
					navigation_state: 'previous-only'
				},
				zip_postal: {
					component_name: ZipPostal,
					navigation_state: 'default',
					requirements: {
						customer__zip_postal: { type: 'zip', required: true },
					},
					dependent_function: this.getAvailability
				},
				date_time: {
					component_name: DateTime,
					navigation_state: 'default',
					requirements: {
						appointment__date: { type: 'date', required: true },
						appointment__time: { type: 'text', required: true },
					},
				},
				customer_information: {
					component_name: CustomerInformation,
					navigation_state: 'default',
					requirements: {
						customer__name: { type: 'customerName', required: true },
						customer__email: { type: 'email', required: true },
						customer__address: { type: 'address', required: true },
						customer__city: { type: 'text', required: true },
						customer__state: { type: 'text', required: true },
						customer__phone1: { type: 'phone', required: true },
					},
				},
				machine_information: {
					component_name: MachineInformation,
					navigation_state: 'default',
					requirements: {
						machine__make: { type: 'text', required: true },
						machine__type: { type: 'text', required: true },
						machine__model_number: { type: 'text', required: true },
						machine__serial_number: { type: 'text', required: true },
						machine__problem_description: { type: 'text', required: true },
						machine__dealer: { type: 'text', required: false },
						machine__purchase_date: { type: 'text', required: false },
					},
				},
				payment_type: {
					component_name: PaymentType,
					navigation_state: 'previous-only',
				},
				summarize: {
					component_name: Summarize,
					navigation_state: 'schedule-final',
					dependent_function: this.makeAppointment
				},
				finalize: {
					component_name: Finalize,
					navigation_state: 'none',
				},
				extended_warranty: {
					component_name: ExtendedWarranty,
					navigation_state: 'default',
					requirements: {
						extended_warranty__provider: { type: 'text', required: true },
						extended_warranty__contract_number: { type: 'text', required: true },
					}
				},
				third_party_payer: {
					component_name: ThirdPartyPayer,
					navigation_state: 'default',
					requirements: {
						payer__first: { type: 'text', required: true },
						payer__last: { type: 'text', required: true },
						payer__email: { type: 'email', required: true },
						payer__address: { type: 'text', required: true },
						payer__city: { type: 'text', required: true },
						payer__state: { type: 'text', required: true },
						payer__zip_postal: { type: 'zip', required: true },
						payer__phone1: { type: 'phone', required: true },
					}
				},
				continuation_warning: {
					component_name: ContinuationWarning,
					navigation_state: 'previous-only'
				},
				recall_warning: {
					component_name: RecallWarning,
					navigation_state: 'previous-only'
				},
				no_direct_schedule: {
					component_name: NoDirectSchedule,
					navigation_state: 'none'
				},
				no_id: {
					component_name: NoID,
					navigation_state: 'none'
				},
				custom_terms: {
					component_name: CustomTerms,
					navigation_state: 'default',
					requirements: {
						appointment__agreed_to_terms: { type: 'bool', required: true }
					}
				},

			},
		};
	}

	componentDidMount = async () => {
		document.addEventListener('keydown', this.onEnterKey, false);
		this.getInitialData();
	};

	validate = (specifiedStep) => {
		const { inputData, validations, stepMap } = this.state;
		const step = specifiedStep || this.state.step;
		const stepRequirements = stepMap[step].requirements;
		if (!stepRequirements) return true;
		var valid = true;
		for (var stepRequirement of Object.keys(stepRequirements)) {
			const { type, required } = stepRequirements?.[stepRequirement];
			if (!required) { continue; }
			const keyArray = stepRequirement.split('__');
			const valueToCheck = inputData?.[keyArray[0]]?.[keyArray[1]];
			const validation = validations[type];
			if (!validation(valueToCheck)) {
				valid = false;
				break;
			}
		}
		return valid;
	};

	getInitialData = async () => {
		const { business, forwardParam } = this.props;
		const { stepMap } = this.state;

		const { client_name, direct_to_zip, allow_virtual_appointments, allow_direct_schedule, offer_time_frames, require_model_serial } = business;

		const department = forwardParam ? decodeURIComponent(forwardParam) : null;
		let [frames_response, makes_response, types_response, dealers_response] = await Promise.all([
			apiRequest({ endpoint: 'v3/publicTimeFrames', parameters: { business_id: business.client_id } }),
			department ? apiRequest({ endpoint: 'v3/publicMakes', parameters: { business_id: business.client_id, department: department } }) : apiRequest({ endpoint: 'v3/publicMakes', parameters: { business_id: business.client_id } }),
			department ? apiRequest({ endpoint: 'v3/publicTypes', parameters: { business_id: business.client_id, department: department } }) : apiRequest({ endpoint: 'v3/publicTypes', parameters: { business_id: business.client_id } }),
			apiRequest({ endpoint: 'v3/publicDealers', parameters: { business_id: business.client_id } }),
		]);
		const { data: frames } = frames_response;
		const { data: makes } = makes_response;
		const { data: types } = types_response;
		const { data: dealers } = dealers_response;
		business.frames = frames;
		business.makes = makes;
		business.types = types;
		business.dealers = dealers;

		document.title = `${client_name} - Schedule`;

		// if offer_time_frames is false, splice out that requirement from the array
		if (!offer_time_frames) {
			stepMap.date_time.requirements.appointment__time.required = false;
		}

		// if require_model_serial is false, splice out those requirements from the array
		if (require_model_serial !== 1) {
			stepMap.machine_information.requirements.machine__model_number.required = false;
			stepMap.machine_information.requirements.machine__serial_number.required = false;
		}



		var stepOrder;
		var stepToSet;

		// If direct_to_zip, do silly stuff, which is just disallowing any payment situations other than standard, i.e. no warranty work
		if (direct_to_zip) {
			stepOrder = ['zip_postal', 'date_time', 'customer_information', 'machine_information', 'payment_type', 'summarize', 'finalize'];
			stepToSet = 'zip_postal';

			if (business?.cyberSettings?.show_custom_terms) {
				stepOrder.splice(stepOrder.length - 2, 0, 'custom_terms');
			}
		} else {
			stepOrder = ['payment_structure'];
			stepToSet = 'payment_structure';
		}

		if (allow_virtual_appointments) {
			stepOrder = ['offer_virtual_appointment'].concat(stepOrder);
			stepToSet = 'offer_virtual_appointment';
		}

		this.setState({ stepOrder: stepOrder, scenarioAsNumber: direct_to_zip ? 1 : null, department, stepMap, step: stepToSet, loading: false, business });

		if (allow_direct_schedule === 0) {
			this.setState({ step: 'no_direct_schedule', loading: false, stepMap: stepMap, business: business, department: department });
		}
	};

	onEnterKey = (event) => {
		if (event.keyCode === 13) {
			const { step } = this.state;
			const acceptableStepsForKeyedForward = ['zip_postal', 'date_time', 'customer_information', 'machine_information', 'extended_warranty', 'third_party_payer'];
			if (acceptableStepsForKeyedForward.includes(step)) {
				this.stepForward(false);
			}
		}
	};

	inputChange = ({ event, otherValue, otherName }) => {
		const { inputData } = this.state;
		var name = event ? event.target.name : false;
		var value = event ? event.target.value : false;
		const useValue = event?.target?.type === 'checkbox' ? event.target.checked : (otherValue || value);
		const useName = otherName || name;
		const keyArray = useName.includes('-') ? useName.split('-') : [useName];
		var newObject = {};
		if (keyArray.length === 1) {
			newObject[keyArray[0]] = useValue;
		} else if (keyArray.length === 2) {
			newObject[keyArray[0]] = {};
			newObject[keyArray[0]][keyArray[1]] = useValue;
		}
		const newInputData = _.merge(inputData, newObject);
		if ((useName === 'machine-type' || useName === 'machine-make' || useName === 'machine-dealer') && useValue === 'other' ) {
			this.toggleOtherTextBoxes(useName);
		} else {
			this.setState({ inputData: newInputData, stepIsValid: this.validate() });
		}
	};

	toggleOtherTextBoxes = (name) => {
		const nameInInputData = name.substring(8);
		const nameInToggle = 'machine_' + name.substring(8);
		const { showOtherTextBoxes, inputData } = this.state;
		const existingValue = inputData.machine[nameInInputData];
		if (existingValue === 'other') {
			inputData.machine[nameInInputData] = '';
		} else {
			inputData.machine[nameInInputData] = 'default';
		}
		showOtherTextBoxes[nameInToggle] = !showOtherTextBoxes[nameInToggle];
		this.setState({
			showOtherTextBoxes: showOtherTextBoxes,
			inputData: inputData,
			stepIsValid: this.validate()
		});
	};

	setPaymentType = (value) => {
		const { inputData } = this.state;
		inputData.customer.payment_type = value;
		this.setState({
			inputData: inputData
		});
	};

	getAvailability = () => {
		console.log('checking for availability');
		const { business, inputData, department } = this.state;
		const limit_scheduling_window = business?.cyberSettings?.limit_scheduling_window;
		return new Promise(async (resolve, reject) => {
			const availability = await apiRequest({ endpoint: 'v3/publicAvailabilityByZip', parameters: { business_id: business.client_id, zip_code: inputData.customer.zip_postal, department: department, omit_zones_list: business.cyberSettings?.omit_zones ? business.cyberSettings?.omit_zones_list : '' } });
			console.log(availability);
			if (availability.message === 'no zones') {
				reject('Unfortunately, we do not yet serve your area. If you believe this is a mistake, please call our office to personally schedule at ' + formatPhoneReadable(business?.client_phone));
			} else if (availability.data.length < 1) {
				reject(`We do serve your area but were unable to find any availability. Please contact our office at ${formatPhoneReadable(business?.client_phone)} to schedule.`);
			} else {
				var availableDates = [];
				var now = LuxonDate.now();
				for (let i = 0; i < availability['data'].length; i++) {
					var date = LuxonDate.fromISO(availability['data'][i]['AvlblDt']);
					if ((availability['data'][i]['SlotsAvailable'] > 0)) {
						if (limit_scheduling_window > 0 && date > now.plus({ weeks: limit_scheduling_window })) {
							continue;
						}
						availableDates.push(availability['data'][i]['AvlblDt'].substring(0,10));
					}
				}
				if (availableDates.length < 1) {
					reject(`We do serve your area but were unable to find any availability. Please contact our office at ${formatPhoneReadable(business?.client_phone)} to schedule.`);
				}
				business.availability_for_zip = availableDates;
				inputData.appointment.zone_number = availability.data[0].ZoneNmbr;
				this.setState({ business: business, inputData: inputData });
				resolve();
			}
		});
	};

	makeAppointment = () => {
		return new Promise(async (resolve, reject) => {
			const { business } = this.props;
			const params = parseAppointmentParamsFromState(this.state);
			if (!params) reject('Something went wrong. Please call Rossware');
			const appointment = await apiRequest({ endpoint: 'v3/publicAppointment', parameters: params });
			if (appointment.success === true) {
				if (business?.cyberSettings?.url_after_schedule) {
					if (this.props.framed) {
						top.window.location.replace(business.cyberSettings.url_after_schedule); // eslint-disable-line no-restricted-globals
					} else {
						window.location.replace(business.cyberSettings.url_after_schedule);
					}
				}
				resolve();
			} else {
				reject('Something went wrong creating your appointment. Please call Rossware');
			}
		});
	};

	stepForward = (chosenScenario) => {
		const { step, stepOrder, business, stepIsValid, inputData, scenario, stepMap } = this.state;
		if (!stepIsValid) { return false; }

		var modifiedStuff = false;

		if (chosenScenario) {
			modifiedStuff = modifyStepMapAndStepOrder({ stepMap, scenario: chosenScenario, business });
		}

		this.setState({ stepOrder: modifiedStuff?.stepOrder || stepOrder, stepMap: modifiedStuff?.stepMap || stepMap, scenario: chosenScenario || scenario, scenarioAsNumber: scenarioStringToNumber(chosenScenario || scenario), business: business });

		const currentStepNumber = indexOf(stepOrder, step);
		const targetStepName = modifiedStuff ? 'zip_postal' : stepOrder[currentStepNumber + 1];
		const dependentFunction = stepMap[step].dependent_function || false;

		if (dependentFunction) {
			this.setState({ loading: true });
			dependentFunction().then(() => {
				this.setState({ step: targetStepName, stepIsValid: this.validate(targetStepName), error: null, loading: false });
			})
				.catch((err) => {
					this.setState({ error: err, loading: false });
				});
		} else {
			this.setState({ step: targetStepName, stepIsValid: this.validate(targetStepName), error: null, loading: false, inputData: inputData });
		}
	};

	renderStepperOrderIndicator() {
		const { stepOrder, step } = this.state;
		return (
			<ul>
				{ renderLis() }
			</ul>
		);
		function renderLis() {
			return stepOrder.map((currentStep, dex) => {
				if (currentStep === step) {
					return (
						<li key={ `orderIndicatorStep${dex}` }><b>{ currentStep }</b></li>
					);
				} else {
					return (
						<li key={ `orderIndicatorStep${dex}` }>{ currentStep }</li>
					);
				}
			});
		}
	}
	stepBack = () => {
		const { step, stepOrder } = this.state;
		const currentStepNumber = indexOf(stepOrder, step);
		const targetStepName = stepOrder[currentStepNumber - 1];
		this.setState({ step: targetStepName, stepIsValid: this.validate(targetStepName), error: null });
	};
	renderContentsByStep(step) {
		const { step: currentStep, stepOrder, stepMap } = this.state;
		const { component_name: ComponentName, navigation_state } = stepMap[step];
		const { business, inputData, scenario, showOtherTextBoxes, error, loading } = this.state;
		if (loading) {
			return (
				<Loader />
			);
		}
		return (
			<Fragment>
				{/* { this.renderStepperOrderIndicator() } */}
				<ComponentName business={ business } inputData={ inputData } scenario={ scenario } stepForward={ this.stepForward } stepBack={ this.stepBack } inputChange={ this.inputChange } setPaymentType={ this.setPaymentType } toggleOtherTextBoxes={ this.toggleOtherTextBoxes } showOtherTextBoxes={ showOtherTextBoxes } />
				{ error && <Alert className="mb-0 mt-3" variant="warning" ><FaExclamationCircle className="errorIcon"/>&nbsp;&nbsp;{error}</Alert> }
				<Navigation displayMode={ navigation_state } stepForward={ this.stepForward } stepBack={ this.stepBack } validated={ this.state.stepIsValid } business={ business } allowStepBack={ !(currentStep === stepOrder[0]) }/>
			</Fragment>
		);
	}
	render() {
		const { step } = this.state;
		return (
			<Fragment>
				{ this.renderContentsByStep(step) }
			</Fragment>
		);
	}
}

export default Schedule;