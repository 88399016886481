import React, { Component } from 'react';

export class ContinuationWarning extends Component {
	render() {
		return (
			<div className="d-flex justify-content-center">
				<p>If we previously did work but were unable to finish and required a subsequent visit, we would have sent you an email with a link to schedule a time for the technician to return. Please use the link we emailed so we can retrieve your information.</p>
			</div>
		);
	}
}

export default ContinuationWarning;
