import React, { Component, Fragment } from 'react';
import { FaBuilding, FaUser } from 'react-icons/fa';
import Header from '../../../global_components/Header';
import PrettyBox from '../../../global_components/PrettyBox';

export class WhoAreYou extends Component {
	componentDidMount() {
		this.props.setStepOrderAndCurrentStep(['initial', 'who_are_you'], 'who_are_you');
	}
	render() {
		const { business } = this.props;

		return (
			<Fragment>
				<Header head="Who are you?" />
				<div className="grid-2-col">
					<PrettyBox action={ () => this.props.stepForward('consumer') } head="Consumer" subHead="I am a consumer wanting to check the status on my job." Icon={ FaUser } color={ business?.cyberSettings?.foreground_color } />
					<PrettyBox action={ () => this.props.stepForward('dealer') } head="Dealer" subHead="I am a dealer wanting to check the status on one of my customer's jobs" Icon={ FaBuilding } color={ business?.cyberSettings?.foreground_color } />
				</div>
			</Fragment>
		);
	}
}

export default WhoAreYou;