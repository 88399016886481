import React, { Component } from 'react';
import { FaCalendarCheck } from 'react-icons/fa';
import { formatTimeFrameReadable } from '../../../helpers/formatting';

export class Confirmed extends Component {
	render() {
		const { confirmation } = this.props;
		return (
			<div className="text-center">
				<h2 className="text-primary"><FaCalendarCheck style={{ color: '#198754' }}/></h2>
				<h5>Appointment Confirmed!</h5>
				<p>Thank you for confirming your appointment. We look forward to seeing you {`${confirmation.time.appointment_description} ${formatTimeFrameReadable(confirmation.time.target_time, 'description')}`}</p>
			</div>
		);
	}
}

export default Confirmed;
