import React, { Component } from 'react';
import { FaCalendarTimes } from 'react-icons/fa';

export class Cancelled extends Component {
	render() {
		return (
			<div className="text-center">
				<h2 className="text-primary"><FaCalendarTimes style={{ color: '#dc3644' }}/></h2>
				<h5>Appointment Cancelled</h5>
				<p>Sorry you weren&apos;t able to keep your appointment.</p>
			</div>
		);
	}
}

export default Cancelled;
