import React, { Component, Fragment } from 'react';
import { Col, Form, ListGroup } from 'react-bootstrap';
import Header from '../../../global_components/Header';
import { getValueFromInputData } from '../../../helpers/objects';
import RequiredLabel from '../../../global_components/RequiredLabel';
import apiRequest from '../../../helpers/data';
import { validateText, validateZip } from '../../../helpers/validation';

export class DealerInformation extends Component {
	state = {
		statusResult: null
	};

	constructor(props) {
		super(props);
		this.zipPostalInput = React.createRef();
	}
	componentDidMount() {
		this.zipPostalInput.current.focus();
	}

	renderChosenSearchMethodInput = () => {
		const { inputData, parentState } = this.props;

		switch (parentState.searchOnForEasyInquiry) {
		case 'last_name':
			return (
				<Fragment>
					<Form.Label className="label">Last name <RequiredLabel /></Form.Label>
					<Form.Control size="sm" defaultValue={ getValueFromInputData(inputData, 'status-last_name') } name="status-last_name" onChange={ (event) => {
						this.props.inputChange({ event });
						this.onInputChange(event);
					} } />
				</Fragment>
			);
		case 'street_address':
			return (
				<Fragment>
					<Form.Label className="label">Street number <RequiredLabel /></Form.Label>
					<Form.Control size="sm" defaultValue={ getValueFromInputData(inputData, 'status-street_address') } name="status-street_address" onChange={ (event) => {
						this.props.inputChange({ event });
						this.onInputChange(event);
					} } />
				</Fragment>
			);
		default:
			return null;
		}
	};

	renderCheckableStatusesList = () => {
		const { statusResult } = this.state;
		const { setJobStatus, stepForward } = this.props;
		console.log(statusResult);

		if (statusResult?.data?.length > 0) {
			return (
				<div>
					Select a result to see its job status
					<ListGroup className="jobStatusList" style={{ marginTop: '1rem', overflow: 'scroll', maxHeight: '130px' }}>
						{ renderListItems() }
					</ListGroup>
				</div>
			);
		} else if (statusResult?.data?.length === 0 || !this.isThisStepValid()) {
			return 'No results found for your search';
		} else {
			return 'Stop typing to see results';
		}

		function renderListItems() {
			return statusResult?.data.map((item, key) => {
				return (
					<ListGroup.Item key={key} onClick={ () => {
						setJobStatus(item);
						stepForward(null, true, true);
					} }>
						#{ item?.invoice_number } - { item?.customer?.name } - { item?.machine?.make } { item?.machine?.type }
					</ListGroup.Item>
				);
			});
		}
	};

	isThisStepValid () {
		const { inputData, parentState } = this.props;
		const { searchOnForEasyInquiry } = parentState;
		switch (searchOnForEasyInquiry) {
		case 'last_name':
			return validateText(inputData.status.last_name) && validateZip(inputData.status.zip_postal);
		case 'street_address':
			return validateText(inputData.status.street_address) && validateZip(inputData.status.zip_postal);
		default:
			return false;
		}
	}

	onInputChange = () => {
		const { business, inputData, parentState } = this.props;
		const { searchOnForEasyInquiry } = parentState;
		this.setState({ statusResult: null });

		function determineParameters(searchOnForEasyInquiry, inputData) {
			switch (searchOnForEasyInquiry) {
			case 'last_name':
				return { business_id: business.client_id, zip_code: inputData.status.zip_postal, last_name: inputData.status.last_name, no_charge: true };
			case 'street_address':
				return { business_id: business.client_id, zip_code: inputData.status.zip_postal, street_address: inputData.status.street_address, no_charge: true };
			default:
				return { business_id: business.client_id, zip_code: inputData.status.zip_postal, last_name: inputData.status.last_name, no_charge: true };
			}
		}

		if (this.isThisStepValid(inputData, searchOnForEasyInquiry)) {
			setTimeout(async () => {
				const statusResult = await apiRequest({ endpoint: 'v3/publicJobStatus', parameters: determineParameters(searchOnForEasyInquiry, inputData) });
				this.setState({ statusResult });
			}, 2000);
		}
	};

	render() {
		const { inputData } = this.props;
		return (
			<Fragment>
				<Header head="Let's find the job you're looking for." />
				<div>
					<Form>
						<Form.Row>
							<Col>
								<Form.Label className="label">Zip code <RequiredLabel /></Form.Label>
								<Form.Control
									size="sm"
									defaultValue={ getValueFromInputData(inputData, 'status-zip_postal') }
									name="status-zip_postal"
									onChange={ (event) => {
										this.props.inputChange({ event });
										this.onInputChange(event);
									}}
									ref={ this.zipPostalInput }
								/>
							</Col>
							<Col>
								{ this.renderChosenSearchMethodInput() }
							</Col>
						</Form.Row>
					</Form>

					{ this.renderCheckableStatusesList() }

				</div>
			</Fragment>

		);
	}
}

export default DealerInformation;
