export function encodeObject(object) {
	if (!object) return '';
	const keys = Object.keys(object);
	return '?' + keys.map((key) => {
		return encodeURIComponent(key) + '=' + encodeURIComponent(object[key]);
	}).join('&');
}

export default async function apiRequest({ endpoint, parameters, body, method }) {
	const preUrl = `${process.env.REACT_APP_API_BASE_URL}/${endpoint}${encodeObject(parameters)}`;
	const url = method === 'POST' ? preUrl : `${preUrl}&backend_key=${process.env.REACT_APP_API_BACKEND_KEY}`;
	const useBody = body ? JSON.stringify({ backend_key: process.env.REACT_APP_API_BACKEND_KEY, ...body }) : undefined;
	let response = await fetch(url, { method, body: useBody });
	let data = await response.json();
	return data;
}
