import React, { Component, Fragment } from 'react';
import { Col, Form } from 'react-bootstrap';
import Header from '../../../global_components/Header';
import { getValueFromInputData } from '../../../helpers/objects';
import RequiredLabel from '../../../global_components/RequiredLabel';

export class Information extends Component {
	constructor(props) {
		super(props);
		this.zipPostalInput = React.createRef();
	}
	componentDidMount() {
		this?.zipPostalInput?.current?.focus();
	}
	render() {
		const { inputData } = this.props;
		return (
			<Fragment>
				<Header head="Tell us a bit about your appointment." />
				<div>
					<Form>
						<Form.Row>
							<Col>
								<Form.Label className="label">Zip code <RequiredLabel /></Form.Label>
								<Form.Control
									size="sm"
									defaultValue={ getValueFromInputData(inputData, 'tracker-zip_postal') }
									name="tracker-zip_postal"
									onChange={ (event) => { this.props.inputChange({ event }); } }
									ref={ this.zipPostalInput }
								/>
							</Col>
							<Col>
								<Form.Label className="label">Invoice number <RequiredLabel /></Form.Label>
								<Form.Control type="number" size="sm" defaultValue={ getValueFromInputData(inputData, 'tracker-invoice') } name="tracker-invoice" onChange={ (event) => { this.props.inputChange({ event }); } } />
							</Col>
						</Form.Row>
					</Form>
				</div>
			</Fragment>

		);
	}
}

export default Information;
