import React, { Component, Fragment } from 'react';
import { FaCheckCircle } from 'react-icons/fa';
import { formatTimeFrameReadable } from '../../../helpers/formatting';

export class Finalize extends Component {
	render() {
		const { inputData, business } = this.props;
		const { appointment } = inputData;
		const { DateTime } = require('luxon');
		const dateTime = DateTime.fromISO(appointment.date);
		const readableDate = dateTime.toLocaleString(DateTime.DATE_HUGE);
		const readableTimeFrame = formatTimeFrameReadable(appointment.time, 'description');

		return (
			<Fragment>
				<div className="text-center">
					<h2 className="text-primary"><FaCheckCircle style={{ color: business?.cyberSettings?.foreground_color }} /></h2>
					<h5>Appointment Scheduled!</h5>
					<p>Thanks! We look forward to seeing you {`${readableDate} ${readableTimeFrame !== 'default' ? readableTimeFrame : ''}`}</p>
					<p>Please expect an email in the next 30 minutes confirming the details of your appointment.</p>
				</div>
			</Fragment>
		);
	}
}

export default Finalize;