import React, { Component, Fragment } from 'react';
import { Col, Form } from 'react-bootstrap';
import RequiredLabel from '../../../global_components/RequiredLabel';
import Flatpickr from 'react-flatpickr';
import { getValueFromInputData } from '../../../helpers/objects';
import { formatTimeFrameReadable } from '../../../helpers/formatting';
import Header from '../../../global_components/Header';

export class Schedule extends Component {
	renderTimeFrames = () => {
		const { business } = this.props;
		const { frames } = business;
		return frames.map((frame, i) => {
			return <option value={ frame.TimeFrame } key={ i }>{ formatTimeFrameReadable(frame.TimeFrame, 'text') }</option>;
		});
	};
	usableDateFromFlatpickr(date) {
		const { DateTime: dt } = require('luxon');
		const dateTime = dt.fromISO(date.toISOString());
		return dateTime.toISODate();
	}
	render() {
		const { business, inputData } = this.props;
		if (business.offer_time_frames) {
			return (
				<Fragment>
					<Header head="Schedule your appointment" subHead="When works best for you?" />
					<div>
						<Form.Row>
							<Col>
								<Form.Label className="label">Date <RequiredLabel /></Form.Label>
								<Flatpickr
									placeholder="Click to select"
									className="form-control form-control-sm"
									onChange={ date => { this.props.inputChange({ otherValue: this.usableDateFromFlatpickr(date[0]), otherName: 'invite-date' }); } }
									options={
										{
											enable: business.availability_for_zip,
											dateFormat: 'm-d-Y',
										}
									}
									ref={c => (this.dateInput = c) }
								/>
							</Col>
							<Col>
								<Form.Label className="label">Time frame <RequiredLabel /></Form.Label>
								<Form.Control size="sm" as="select" defaultValue={ getValueFromInputData(inputData, 'invite-time') } name="invite-time" onChange={ (event) => { this.props.inputChange({ event }); } }>
									<option disabled value="default">Select</option>
									{ this.renderTimeFrames() }
								</Form.Control>
							</Col>
						</Form.Row>
						<Form.Row>
							<Col>
								<Form.Label className="label">First and last name <RequiredLabel /></Form.Label>
								<Form.Control size="sm" defaultValue={ getValueFromInputData(inputData, 'invite-name') } name="invite-name" onChange={ (event) => { this.props.inputChange({ event }); } } maxLength="50"></Form.Control>
							</Col>
						</Form.Row>
					</div>
				</Fragment>
			);

		} else {
			return (
				<Fragment>
					<Header head="Schedule your appointment" subHead="When works best for you?" />
					<div>
						<Form.Row>
							<Col>
								<Form.Label className="label">Date <RequiredLabel /></Form.Label>
								<Flatpickr
									placeholder="Click to select"
									className="form-control form-control-sm"
									onChange={ date => { this.props.inputChange({ otherValue: this.usableDateFromFlatpickr(date[0]), otherName: 'invite-date' }); } }
									options={
										{
											enable: business.availability_for_zip,
											dateFormat: 'm-d-Y',
										}
									}
									ref={c => (this.dateInput = c) }
								/>
							</Col>
							<Col>
								<Form.Label className="label">First and last name <RequiredLabel /></Form.Label>
								<Form.Control size="sm" defaultValue={ getValueFromInputData(inputData, 'invite-name') } name="invite-name" onChange={ (event) => { this.props.inputChange({ event }); } } maxLength="50"></Form.Control>
							</Col>
						</Form.Row>
					</div>
				</Fragment>
			);
		}
	}
}

export default Schedule;
