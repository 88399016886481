import { formatPhoneForDatabase } from './formatting';

function renameKeys (keysMap, obj) {
	return Object.keys(obj).reduce(
		(acc, key) => ({
			...acc,
			...{ [keysMap[key] || key]: obj[key] }
		}),
		{}
	);
}

export default function transformBusinessToUnderscore(business) {
	const keysMap = {
		AllowDirectSchedule: 'allow_direct_schedule',
		AllowVirtualAppointments: 'allow_virtual_appointments',
		AllowCancellation: 'allow_cancellation',
		ClientName: 'client_name',
		CyberColors: 'cyber_colors',
		ShowCyberLogo: 'show_cyber_logo',
		CyberLogoURL: 'cyber_logo_url',
		DirectToZipPg: 'direct_to_zip',
		ExcludePayMethods: 'exclude_pay_methods',
		FrameForLinkedOpen: 'frame_for_linked_open',
		FrameForStatusOpen: 'frame_for_status_open',
		FrameForTechOpen: 'frame_for_tech_open',
		IsCanadian: 'is_canadian',
		LiberalizeInquiries: 'liberalize_job_status_inquiries',
		OfferTimeFrms: 'offer_time_frames',
		PhoneticName: 'phonetic_name',
		RqrModSer: 'require_model_serial',
		SDCO: 'cyber_office_allowed',
		ShowHistory: 'show_history',
		TechFrames: 'tech_frames',
		TechStatus: 'tech_status',
		TelNmbr: 'client_phone',
		TwilioNumber: 'client_robo_phone',
		id: 'client_id',
		t_email: 'client_email',
	};
	const object = business.data;
	return renameKeys(keysMap, object);
}

export function getValueFromInputData(inputData, name) {
	const keyArray = name.includes('-') ? name.split('-') : [name];
	var value;
	if (keyArray.length === 1) {
		value = inputData[keyArray[0]];
	} else if (keyArray.length === 2) {
		value = inputData[keyArray[0]][keyArray[1]];
	}
	return value;
}

export function scenarioStringToNumber(scenario) {
	const scenarios = {
		regular_schedule: 1,
		original_warranty: 2,
		extended_warranty: 3,
		third_party: 4,
	};
	return scenarios[scenario];
}


function paymentStringToNumber(payment) {
	const payments = {
		cash: 1,
		check: 2,
		card: 3,
	};
	return payments[payment] || 1;
}


export function parseAppointmentParamsFromState(state) {
	const { inputData, business, scenarioAsNumber } = state;
	const { customer, payer, appointment, machine, extended_warranty } = inputData;

	const inputObject = {
		business_id: business?.client_id || false,
		customer_address: customer?.address || false,
		customer_city: customer?.city || false,
		customer_email: customer?.email || false,
		customer_name: customer?.name || false,
		customer_state: customer?.state || false,
		customer_phone_1: formatPhoneForDatabase(customer?.phone1) || false,
		customer_phone_2: formatPhoneForDatabase(customer?.phone2) || '',
		customer_zip: customer?.zip_postal?.toUpperCase() || false,
		date_requested: appointment?.date || '',
		time_frame: appointment?.time === 'default' ? '' : appointment.time,
		virtual_appointment: appointment?.virtual ? 1 : 0,
		dealer: machine?.dealer === 'default' ? '' : machine.dealer,
		description: machine?.problem_description || '',
		notes: machine?.extra_notes,
		item_make: machine?.make || false,
		item_type: machine?.type || false,
		model_number: machine?.model_number,
		serial_number: machine?.serial_number,
		purchase_date: machine?.purchase_date,
		payer_address: payer?.address || '',
		payer_city: payer?.city || '',
		payer_email: payer?.email || '',
		payer_first_name: payer?.first || '',
		payer_last_name: payer?.last || '',
		payer_organization: payer?.organization || '',
		payer_state: payer?.state === 'default' ? '' : payer.state,
		payer_phone_1: formatPhoneForDatabase(payer?.phone1) || '',
		payer_phone_2: formatPhoneForDatabase(payer?.phone2) || '',
		payer_zip: payer?.zip_postal || '',
		payment_type: paymentStringToNumber(customer.payment_type),
		payment_structure: scenarioAsNumber || false,
		warranty_provider: extended_warranty.provider || '',
		warranty_number: extended_warranty.contract_number || '',
		purchase_order_number: extended_warranty.purchase_order_number || '',
		zone_number: appointment.zone_number || false,
	};
	const baseRequirements = ['payment_structure', 'business_id', 'zone_number', 'customer_first_name', 'customer_last_name', 'customer_email', 'customer_address', 'customer_city', 'customer_state', 'customer_zip', 'customer_phone_1', 'date_requested', 'item_make', 'item_type', 'description'];

	const payStructureRequirements = {
		1: ['payment_type'],
		2: ['model_number', 'serial_number', 'dealer', 'purchase_date'],
		3: ['model_number', 'serial_number', 'dealer', 'purchase_date', 'warranty_provider', 'warranty_number'],
		4: ['payer_first_name', 'payer_last_name', 'payer_address', 'payer_city', 'payer_state', 'payer_zip', 'payer_phone_1', 'payer_email'],
	};

	const requirements = baseRequirements.concat(payStructureRequirements[scenarioAsNumber]);

	const isInputObjectValid = () => {
		requirements.forEach((requirement) => {
			if (inputObject[requirement] === false) {
				return false;
			}
		});
		return true;
	};

	if (isInputObjectValid) { return inputObject; } else { return false; }
}

export function modifyStepMapAndStepOrder({ stepMap, scenario, business }) {
	if (scenario === 'extended_warranty' || scenario === 'original_warranty') {
		stepMap.machine_information.requirements.machine__make.required = true;
		stepMap.machine_information.requirements.machine__type.required = true;
		stepMap.machine_information.requirements.machine__model_number.required = true;
		stepMap.machine_information.requirements.machine__serial_number.required = true;
		stepMap.machine_information.requirements.machine__dealer.required = true;
		stepMap.machine_information.requirements.machine__purchase_date.required = true;
		stepMap.machine_information.requirements.machine__problem_description.required = true;
	} else if (business.require_model_serial === 0) {
		stepMap.machine_information.requirements.machine__make.required = true;
		stepMap.machine_information.requirements.machine__type.required = true;
		stepMap.machine_information.requirements.machine__problem_description.required = true;
		stepMap.machine_information.requirements.machine__model_number.required = false;
		stepMap.machine_information.requirements.machine__serial_number.required = false;
		stepMap.machine_information.requirements.machine__dealer.required = false;
		stepMap.machine_information.requirements.machine__purchase_date.required = false;
	} else if (business.require_model_serial === 1) {
		stepMap.machine_information.requirements.machine__make.required = true;
		stepMap.machine_information.requirements.machine__type.required = true;
		stepMap.machine_information.requirements.machine__model_number.required = true;
		stepMap.machine_information.requirements.machine__serial_number.required = true;
		stepMap.machine_information.requirements.machine__problem_description.required = true;
		stepMap.machine_information.requirements.machine__dealer.required = false;
		stepMap.machine_information.requirements.machine__purchase_date.required = false;
	}

	var stepOrder;

	if (scenario === 'regular_schedule') {
		stepOrder = ['payment_structure', 'zip_postal', 'date_time', 'customer_information', 'machine_information', 'payment_type', 'summarize', 'finalize'];
	} else if (scenario === 'original_warranty') {
		stepOrder = ['payment_structure', 'zip_postal', 'date_time', 'customer_information', 'machine_information', 'summarize', 'finalize'];
	} else if (scenario === 'extended_warranty') {
		stepOrder = ['payment_structure', 'zip_postal', 'date_time', 'customer_information', 'machine_information', 'extended_warranty', 'summarize', 'finalize'];
	} else if (scenario === 'third_party') {
		stepOrder = ['payment_structure', 'zip_postal', 'date_time', 'customer_information', 'machine_information', 'third_party_payer', 'summarize', 'finalize'];
	}

	if (business.allow_virtual_appointments) {
		stepOrder = ['offer_virtual_appointment'].concat(stepOrder);
	}

	if (business?.cyberSettings?.show_custom_terms) {
		stepOrder.splice(stepOrder.length - 2, 0, 'custom_terms');
	}

	return { stepMap, stepOrder };
}

export function getZipPostalFromConfirmation(confirmation, isCanadian) {
	// potential problem here if zip code is not found in address line 2
	const { address2 } = confirmation.location;
	const zipPostalMatch = isCanadian ? address2.match(/[a-z][0-9][a-z]\s*[0-9][a-z][0-9]/i) : address2.match(/[0-9][0-9][0-9][0-9][0-9]/);
	if (zipPostalMatch?.length === 1) { return zipPostalMatch[0]; } else { return false; }
}

export function getZipPostalFromInvite(invite, isCanadian) {
	// potential problem here if zip code is not found in address line 2
	const { address2 } = invite.location;
	const zipPostalMatch = isCanadian ? address2.match(/[a-z][0-9][a-z]\s*[0-9][a-z][0-9]/i) : address2.match(/[0-9][0-9][0-9][0-9][0-9]/);

	if (zipPostalMatch?.length === 1) { return zipPostalMatch[0]; } else { return false; }
}