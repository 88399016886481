import React, { Component, Fragment } from 'react';
import { FaMoneyBillWave, FaMoneyCheckAlt, FaRegCreditCard } from 'react-icons/fa';
import Header from '../../../global_components/Header';
import PrettyBox from '../../../global_components/PrettyBox';

export class PaymentType extends Component {
	onChange = (value) => {
		this.props.setPaymentType(value);
		this.props.stepForward(false);
	};

	render() {
		const { business } = this.props;
		return (
			<Fragment>
				<Header head="How will you pay for this appointment?" />
				<div className="grid-3-col">
					{ [2,3,6,7].includes(business.exclude_pay_methods) &&
					<Fragment>
						<PrettyBox action={ () => this.onChange('cash') } head="Cash" Icon={ FaMoneyBillWave } business={ business } color={ business?.cyberSettings?.foreground_color } />
						<PrettyBox action={ () => this.onChange('card') } head="Credit/Debit" Icon={ FaRegCreditCard } business={ business } color={ business?.cyberSettings?.foreground_color } />
					</Fragment>
					}
					{ ![2,3,6,7].includes(business.exclude_pay_methods) &&
					<Fragment>
						<PrettyBox action={ () => this.onChange('cash') } head="Cash" Icon={ FaMoneyBillWave } business={ business } color={ business?.cyberSettings?.foreground_color } />
						<PrettyBox action={ () => this.onChange('check') } head="Check" Icon={ FaMoneyCheckAlt } business={ business } color={ business?.cyberSettings?.foreground_color } />
						<PrettyBox action={ () => this.onChange('card') } head="Credit/Debit" Icon={ FaRegCreditCard } business={ business } color={ business?.cyberSettings?.foreground_color } />
					</Fragment>
					}
				</div>
			</Fragment>
		);
	}
}

export default PaymentType;
