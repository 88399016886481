import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import React from 'react';
import ReactDOM from 'react-dom';
import './style/bootstrap.css';
import './style/index.css';
import './style/grid.css';
import 'flatpickr/dist/themes/light.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

const urlSearchParams = new URLSearchParams(window.location.search);
const params = Object.fromEntries(urlSearchParams.entries());

// ROUTING FROM OLD DOMAINs (cyberoffice.io & cyberoffice.app)

// routed entries (/r.php)

if (window.location.pathname === '/r.php') {
	const translator = Object.keys(params)[0];
	const serviceIdent = translator.substring(0,1) === '1' ? translator.substring(0,2) : translator.substring(0,1);
	const service = { 11: { path: 'invite' }, 12: { path: 'confirm' }, 2: { path: 'track-technician' }, 3: { path: 'check-status' } }[ serviceIdent ];
	const addParams = [];

	if (service.path === 'invite') {
		addParams.push(translator.substring(2,6));
		addParams.push(translator.substring(6));
	}
	if (service.path === 'confirm') {
		addParams.push(translator.substring(2,6));
		addParams.push(translator.substring(6));
	}
	if (service.path === 'track-technician') {
		addParams.push(translator.substring(1,5));
		addParams.push([
			translator.substring(15, translator.length),
			translator.substring(5, 15),
		].join(''));
	}
	if (service.path === 'check-status') {
		addParams.push(translator.substring(1,5));
		addParams.push([
			translator.substring(15, translator.length),
			translator.substring(5, 15).replace(/^0+/, ''), // replace removes leading zeroes
		].join(''));
	}

	window.location.replace(`${window.location.origin}/${service.path}/${addParams.join('/')}`);
}

// this covers everything outside of routed entries

if (window.location.pathname === '/sched.php') {
	if (params.sched_item_id) {
		window.location.replace(`${window.location.origin}/confirm/${params.id}/${params.sched_item_id}`);
	} else if (params.link) {
		window.location.replace(`${window.location.origin}/invite/${params.id}/${params.link}`);
	} else if (params.id && params.department) {
		window.location.replace(`${window.location.origin}/schedule/${params.id}/${params.department}`);
	} else if (params.id) {
		window.location.replace(`${window.location.origin}/schedule/${params.id}`);
	}
}
if (window.location.pathname === '/techStatus.php') {
	if (params.zipCd && params.inv) {
		window.location.replace(`${window.location.origin}/track-technician/${params.id}/${params.zipCd}${params.inv}`);
	} else if (params.id) {
		window.location.replace(`${window.location.origin}/track-technician/${params.id}`);
	}
}
if (window.location.pathname === '/jobStatus.php') {
	if (params.zipCd && params.inv) {
		window.location.replace(`${window.location.origin}/check-status/${params.id}/${params.zipCd}${params.inv}`);
	} else if (params.id) {
		window.location.replace(`${window.location.origin}/check-status/${params.id}`);
	}
}

ReactDOM.render(
	<App />,
	document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
