import React, { Component, Fragment } from 'react';
import { Col, ListGroup, Row } from 'react-bootstrap';
import { FaCheck, FaPlay, FaRegCircle } from 'react-icons/fa';
import Header from '../../../global_components/Header';
import { formatTimeFrameReadable } from '../../../helpers/formatting';
import BadgeThing from '../../../global_components/BadgeThing';

export class Tracking extends Component {
	renderJobsList = () => {
		const { tracking, business } = this.props;
		var { selected: customerJob, all: allJobs } = tracking;
		allJobs.sort((a, b) => {
			return a.ordinal - b.ordinal;
		});

		const statuses = {
			'In-progress': { class_name: 'warning', icon: FaPlay },
			'Complete': { class_name: 'success', icon: FaCheck },
			'Pending': { class_name: '', icon: FaRegCircle },
		};

		return allJobs.map((job, i) => {
			const Icon = statuses[job.status] ? statuses[job.status].icon : FaRegCircle;
			if (customerJob.ordinal === job.ordinal) {
				return (
					<ListGroup.Item key={ i } as="li" variant={ statuses[job.status] ? statuses[job.status].class_name : '' } className="font-weight-bold">
						<div>
							Your appointment
							<span className="float-right"><span className="hideOnVerySmall">{ customerJob.status }</span> { <Icon /> }</span>
						</div>
					</ListGroup.Item>
				);
			}
			return (
				<ListGroup.Item key={ i } as="li" variant={ statuses[job.status] ? statuses[job.status].class_name : '' }>
					<div>
						Appointment #{ `${i + 1}` } { business.tech_frames === 1 && `(${formatTimeFrameReadable(job.time_frame, 'text')})` }
						<span className="float-right"><span className="hideOnVerySmall">{ job.status || 'Pending' }</span> { <Icon /> }</span>
					</div>
				</ListGroup.Item>
			);
		});
	};

	render() {
		const { tracking } = this.props;
		const { selected, all: allJobs, technician } = tracking;

		return (
			<Fragment>
				<Header head="Technician tracking" subHead="We found the following information for you" />
				<div>
					<Row>
						<Col>
							<Row>
								<Col>
									<BadgeThing label="Invoice number" content={ `${selected.invoice_number}` }/>
								</Col>
								<Col>
									<BadgeThing label="Position" content={ `${selected.ordinal} of ${allJobs.length}` }/>
								</Col>
							</Row>
							<Row>
								<Col>
									<BadgeThing label="Time frame" content={ formatTimeFrameReadable(selected.time_frame, 'text') }/>
								</Col>
								<Col>
									<BadgeThing label="Status" content={ selected.status }/>
								</Col>
							</Row>
							<Row>
								<Col>
									<BadgeThing label="Technician" content={ technician.code }/>
								</Col>
								<Col>
									<BadgeThing label="Last updated" content={ selected.lastUpdated }/>
								</Col>
							</Row>
						</Col>
						<Col md="6">
							<ListGroup as="ul" className="technicianTrackingList">
								{ this.renderJobsList() }
							</ListGroup>
						</Col>

					</Row>
				</div>
			</Fragment>
		);
	}
}

export default Tracking;
