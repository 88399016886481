import React from 'react';
import './style/loader.css';
import {
	BrowserRouter as Router,
	Switch,
	Route,
} from 'react-router-dom';
import Schedule from './sections/schedule/Schedule';
import TrackTechnician from './sections/tracktechnician/TrackTechnician';
import JobStatus from './sections/jobstatus/JobStatus';
import Confirm from './sections/confirm/Confirm';
import Go from './sections/Go';
import Invite from './sections/invite/Invite';

export default function App() {

	function inIframe () {
		try {
			return window.self !== window.top;
		} catch (e) {
			return true;
		}
	}

	const framed = inIframe();

	function dealWithBackslashesInDepartment() {
		const location = window.location.pathname;
		const split = location.split('/');
		if (split.length > 4) {
			let returnArr = [];
			for (let dex = 0; dex < split.length; dex++) {
				if (dex < 3) {
					returnArr.push(split[dex], '/');
				} else if (dex < split.length - 1) {
					returnArr.push(split[dex], encodeURIComponent('/'));
				} else {
					returnArr.push(split[dex]);
				}
			}
			window.location.replace(`${window.location.origin}${returnArr.join('')}`);
		}
	}
	dealWithBackslashesInDepartment();

	return (
		<Router>
			<Switch>
				{/* standard schedule */}
				<Route exact path="/schedule/:businessParam">
					<Go tool={ Schedule } framed={ framed } toolName="schedule"/>
				</Route>

				{/* standard schedule with department */}
				<Route exact path="/schedule/:businessParam/:forwardParam">
					<Go tool={ Schedule } framed={ framed } toolName="schedule"/>
				</Route>

				{/* invite to confirm */}
				<Route exact path="/confirm/:businessParam/:forwardParam">
					<Go tool={ Confirm } framed={ framed } toolName="schedule"/>
				</Route>

				{/* invite to schedule */}
				<Route exact path="/invite/:businessParam/:forwardParam">
					<Go tool={ Invite } framed={ framed } toolName="schedule"/>
				</Route>

				{/* standard technician tracking */}
				<Route exact path="/track-technician/:businessParam">
					<Go tool={ TrackTechnician } framed={ framed } toolName="technician"/>
				</Route>

				{/* invite to track technician */}
				<Route exact path="/track-technician/:businessParam/:forwardParam">
					<Go tool={ TrackTechnician } framed={ framed } toolName="technician"/>
				</Route>

				{/* standard job status */}
				<Route exact path="/check-status/:businessParam">
					<Go tool={ JobStatus } framed={ framed } toolName="job"/>
				</Route>

				{/* invite to job status */}
				<Route exact path="/check-status/:businessParam/:forwardParam">
					<Go tool={ JobStatus } framed={ framed } toolName="job"/>
				</Route>
			</Switch>
		</Router>
	);
}
