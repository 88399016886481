import React, { Component, Fragment } from 'react';
import { Col, Form } from 'react-bootstrap';
import Header from '../../../global_components/Header';
import Flatpickr from 'react-flatpickr';
import { formatTimeFrameReadable } from '../../../helpers/formatting';
import { getValueFromInputData } from '../../../helpers/objects';
import RequiredLabel from '../../../global_components/RequiredLabel';

export class DateTime extends Component {
	state = {
		date: new Date()
	};
	constructor(props) {
		super(props);
		this.dateInput = React.createRef();
	}
	componentDidMount() {
		this.dateInput?.current?.node?.focus();
	}
	renderTimeFrames = () => {
		const { business } = this.props;
		const { frames } = business;
		return frames.map((frame, i) => {
			return <option value={ frame.TimeFrame } key={ i }>{ formatTimeFrameReadable(frame.TimeFrame,'text') }</option>;
		});
	};
	usableDateFromFlatpickr(date) {
		const { DateTime: dt } = require('luxon');
		const dateTime = dt.fromISO(date.toISOString());
		return dateTime.toISODate();
	}
	render() {
		const { inputData, business } = this.props;
		if (business.offer_time_frames) {
			return (
				<Fragment>
					<Header head="When?" subHead="Great, we serve your area! When works best for you?" />
					<div>
						<Form>
							<Form.Row>
								<Col>
									<Form.Label className="label">Date <RequiredLabel /></Form.Label>
									<Flatpickr
										placeholder="Click to select"
										className="form-control form-control-sm"
										onChange={ date => { this.props.inputChange({ otherValue: this.usableDateFromFlatpickr(date[0]), otherName: 'appointment-date' }); } }
										options={
											{
												enable: business.availability_for_zip,
												dateFormat: 'm-d-Y',
											}
										}
										defaultValue={ inputData.appointment.date }
										ref={ this.dateInput }
									/>
								</Col>
								<Col>
									<Form.Label className="label">Time frame <RequiredLabel /></Form.Label>
									<Form.Control size="sm" as="select" defaultValue={ getValueFromInputData(inputData, 'appointment-time') } name="appointment-time" onChange={ (event) => { this.props.inputChange({ event }); } }>
										<option disabled value="default">Select</option>
										{ this.renderTimeFrames() }
									</Form.Control>
								</Col>
							</Form.Row>
						</Form>
					</div>
				</Fragment>
			);
		} else {
			return (
				<Fragment>
					<Header head="When?" subHead="Great, we serve your area! When works best for you?" />
					<div className="d-flex justify-content-center">
						<Form.Group>
							<Form.Label className="label">Date <RequiredLabel /></Form.Label>
							<Flatpickr
								placeholder="Click to select"
								className="form-control form-control-sm halfFormControl"
								onChange={ date => { this.props.inputChange({ otherValue: this.usableDateFromFlatpickr(date[0]), otherName: 'appointment-date' }); } }
								options={
									{
										enable: business.availability_for_zip,
										dateFormat: 'm-d-Y',
									}
								}
								defaultValue={ inputData.appointment.date }
								ref={c => (this.dateInput = c) }
							/>
						</Form.Group>

					</div>
				</Fragment>
			);
		}
	}
}

export default DateTime;
