import React, { Component, Fragment } from 'react';
import { Alert } from 'react-bootstrap';
import { FaExclamationCircle } from 'react-icons/fa';
import Loader from '../../global_components/Loader';
import apiRequest from '../../helpers/data';
import { indexOf } from 'lodash';
import { validateText, validateTime, validateDate, validateZip } from '../../helpers/validation';
import _ from 'lodash';
import Initial from './steps/Initial';
import Information from './steps/Information';
import Tracking from './steps/Tracking';
import Navigation from '../../global_components/Navigation';

export class TrackTechnician extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			error: null,
			stepIsValid: true,
			step: 'initial',
			stepOrder: ['initial', 'information', 'tracking'],
			scenarios: {
				initial: ['initial'],
			},
			inputData: {
				tracker: {
					zip_postal: '',
					invoice: '',
				},
			},
			validations: {
				text: validateText,
				time: validateTime,
				date: validateDate,
				zip: validateZip,
			},
			stepMap: {
				initial: {
					component_name: Initial,
					navigation_state: 'tracktechnician-initial',
				},
				information: {
					component_name: Information,
					navigation_state: 'default',
					requirements: [
						{ name: 'tracker-zip_postal', type: 'zip' },
						{ name: 'tracker-invoice', type: 'text' },
					],
					dependent_function: this.getTracking
				},
				tracking: {
					component_name: Tracking,
					navigation_state: 'tracktechnician-final',
				},
				forwardedTracking: {
					component_name: Tracking,
					navigation_state: 'none',
				},
			},
		};
	}

	async componentDidMount() {
		document.addEventListener('keydown', this.onEnterKey, false);
		const { business, forwardParam, globalError } = this.props;
		const initialStepOrNo = business?.cyberSettings?.show_initial_page ? 'initial' : 'information';
		this.setState({ step: initialStepOrNo });

		document.title = `${business.client_name} - Track technician`;

		if (business.tech_status !== 1) {
			globalError('This business has not enabled technician tracking. If you are the business owner and would like to utilize this feature, simply check the box in your SD-CyberLink utility!');
			return false;
		}

		// if forwarded
		if (forwardParam) {
			const zipPostal = business.is_canadian ? forwardParam.substring(0,6) : forwardParam.substring(0,5);
			const invoice = business.is_canadian ? forwardParam.substring(6) : forwardParam.substring(5);
			const { client_id } = business;
			const trackingInfo = await apiRequest({ endpoint: 'v3/getTechStatus', parameters: { user_id: client_id, zip_code: zipPostal, invoice_number: invoice, no_increment: false } });

			if (trackingInfo.selected) {
				const { DateTime } = require('luxon');
				const now = DateTime.local().toLocaleString(DateTime.DATETIME_MED);
				trackingInfo.selected.lastUpdated = now;
				this.setState({ business: business, tracking: trackingInfo, loading: false, step: 'forwardedTracking' });
			} else {
				globalError('We were unable to find tracking information given the link provided. Please check with your service provider if you believe this is in error.');
				return false;
			}
		}
		this.setState({ business: business, loading: false });
	}

	onEnterKey = (event) => {
		if (event.keyCode === 13) {
			const { step } = this.state;
			const acceptableStepsForKeyedForward = ['information'];
			if (acceptableStepsForKeyedForward.includes(step)) {
				this.stepForward(false);
			}
		}
	};

	getTracking = async () => {
		return new Promise(async (resolve, reject) => {
			const { business, inputData } = this.state;
			const { client_id } = business;
			const trackingInfo = await apiRequest({ endpoint: 'v3/getTechStatus', parameters: { user_id: client_id, zip_code: inputData.tracker.zip_postal, invoice_number: inputData.tracker.invoice, no_increment: false } });
			if (!trackingInfo.selected) {
				reject('We could not find any tracking information with the information you entered. This may be because your appointment is not today.');
			} else {
				const { DateTime } = require('luxon');
				const now = DateTime.local().toLocaleString(DateTime.DATETIME_MED);
				trackingInfo.selected.lastUpdated = now;
				this.setState({ tracking: trackingInfo });
				resolve();
			}
		});
	};

	updateTracking = async () => {
		this.setState({ loading: true });
		const { business, inputData } = this.state;
		const { client_id } = business;
		const trackingInfo = await apiRequest({ endpoint: 'v3/getTechStatus', parameters: { user_id: client_id, zip_code: inputData.tracker.zip_postal, invoice_number: inputData.tracker.invoice, no_increment: false } });
		if (!trackingInfo.selected) {
			this.setState({ loading: false, error: 'We were unable to refresh tracking info. This may be because your appointment date has since passed.' });
		} else {
			const { DateTime } = require('luxon');
			const now = DateTime.local().toLocaleString(DateTime.DATETIME_MED);
			trackingInfo.selected.lastUpdated = now;
			this.setState({ tracking: trackingInfo, loading: false });
		}
	};

	renderContentsByStep(step) {
		const { error } = this.state;
		const { component_name: ComponentName, navigation_state } = this.state.stepMap[step];
		const { business, tracking, inputData } = this.state;
		return (
			<Fragment>
				<ComponentName business={ business } inputData={ inputData } tracking={ tracking } inputChange={ this.inputChange } />
				{ error && <Alert className="mt-3 mb-0" variant="warning"><FaExclamationCircle className="errorIcon"/>&nbsp;&nbsp;{error}</Alert> }
				<Navigation displayMode={navigation_state} stepForward={ this.stepForward } stepBack={ this.stepBack } updateTracking={ this.updateTracking } validated={ this.state.stepIsValid } business={ business }/>
			</Fragment>
		);
	}

	inputChange = ({ event, otherValue, otherName }) => {
		const { inputData } = this.state;
		var name = event ? event.target.name : false;
		var value = event ? event.target.value : false;
		const useValue = otherValue || value;
		const useName = otherName || name;
		const keyArray = useName.includes('-') ? useName.split('-') : [useName];
		var newObject = {};
		if (keyArray.length === 1) {
			newObject[keyArray[0]] = useValue;
		} else if (keyArray.length === 2) {
			newObject[keyArray[0]] = {};
			newObject[keyArray[0]][keyArray[1]] = useValue;
		}
		const newInputData = _.merge(inputData, newObject);
		this.setState({ inputData: newInputData, stepIsValid: this.validate() });
	};

	stepForward = (andAddStepsFor) => {
		const { step, stepOrder, business, stepIsValid } = this.state;
		if (!stepIsValid) { return false; }
		const { initial } = this.state.scenarios;
		var newStepOrder;
		var addedSteps;
		if (andAddStepsFor) {
			const addedStepsKey = `${andAddStepsFor}_adds`;
			addedSteps = this.state.scenarios[addedStepsKey];
			newStepOrder = initial.concat(addedSteps);
			this.setState({ stepOrder: newStepOrder, business: business });
		}
		const relevantStepOrder = newStepOrder || stepOrder;
		const currentStepNumber = indexOf(relevantStepOrder, step);
		const targetStepName = relevantStepOrder[currentStepNumber + 1];
		const dependentFunction = this.state.stepMap[step].dependent_function || false;
		if (dependentFunction) {
			this.setState({ loading: true });
			dependentFunction().then(() => {
				this.setState({ step: targetStepName, stepIsValid: this.validate(targetStepName), error: null, loading: false });
			})
				.catch((err) => {
					this.setState({ error: err, loading: false });
				});
		} else {
			this.setState({ step: targetStepName, stepIsValid: this.validate(targetStepName), error: null, loading: false });
		}
	};

	stepBack = () => {
		const { step, stepOrder } = this.state;
		const currentStepNumber = indexOf(stepOrder, step);
		const targetStepName = stepOrder[currentStepNumber - 1];
		this.setState({ step: targetStepName, stepIsValid: this.validate(targetStepName), error: null });
	};

	validate = (specifiedStep) => {
		const { inputData, validations, stepMap } = this.state;
		const step = specifiedStep || this.state.step;
		const stepRequirements = stepMap[step].requirements;
		if (!stepRequirements) return true;
		var valid = true;
		for (var stepRequirement of stepRequirements) {
			const { name, type } = stepRequirement;
			const keyArray = name.includes('-') ? name.split('-') : [name];
			var valueToCheck;
			if (keyArray.length === 1) {
				valueToCheck = inputData[keyArray[0]];
			} else if (keyArray.length === 2) {
				valueToCheck = inputData[keyArray[0]][keyArray[1]];
			}
			const validation = validations[type];
			if (!validation(valueToCheck)) {
				valid = false;
				break;
			}
		}
		return valid;
	};

	render() {
		const { loading, step } = this.state;
		if (loading) {
			return (
				<Loader />
			);
		} else {
			return (
				<Fragment>
					{ this.renderContentsByStep(step) }
				</Fragment>
			);
		}
	}
}

export default TrackTechnician;
