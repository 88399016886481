import React, { Component } from 'react';
import { FaCalendarAlt } from 'react-icons/fa';
import { formatTimeFrameReadable, formatDateForSummary } from '../../../helpers/formatting';

export class Rescheduled extends Component {
	render() {
		const { inputData } = this.props;
		return (
			<div className="text-center">
				<h2 className="text-primary"><FaCalendarAlt style={{ color: '' }}/></h2>
				<h5>Appointment Rescheduled!</h5>
				<p>Thank you for rescheduling your appointment. We look forward to seeing you {`${formatDateForSummary(inputData.reschedule.date)} ${formatTimeFrameReadable(inputData.reschedule.time, 'description')}`}</p>
			</div>
		);
	}
}

export default Rescheduled;
