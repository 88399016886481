import React, { Component, Fragment } from 'react';
import { Col, ListGroup, Row } from 'react-bootstrap';
import { FaCheck, FaRegCalendarAlt, FaRegCalendarCheck, FaToolbox, FaTruck, FaFileSignature, FaQuestion, FaEllipsisH } from 'react-icons/fa';
import Header from '../../../global_components/Header';
import BadgeThing from '../../../global_components/BadgeThing';


export class Status extends Component {
	componentDidMount() {
		this.renderNarrativeHistory();
	}
	renderNarrativeHistory = () => {
		const { status } = this.props;
		const { job_history } = status;
		const jobHistoryArray = job_history.split(/\r?\n/);
		return jobHistoryArray.map((element, i) => {
			return <ListGroup.Item key={ i }>{ element }</ListGroup.Item>;
		});
	};

	render() {
		const { status, business } = this.props;
		const statuses = {
			1: { status: 'Working to schedule', description: 'We are working to make a new appointment with the customer', icon: FaRegCalendarAlt },
			2: { status: 'Appointment scheduled', description: 'Job is in progress and has an appointment currently scheduled', icon: FaRegCalendarCheck },
			3: { status: 'Dispatched to technician', description: 'Appointment is dispatched to the tech', icon: FaToolbox },
			4: { status: 'Appointment complete, job pending', description: 'Tech has fulfilled the appointment, but (and for unspecified reason) job is not yet complete', icon: FaEllipsisH },
			5: { status: 'Parts on order', description: 'Parts are on order', icon: FaTruck },
			6: { status: 'Pending authorization', description: 'Pending authorization', icon: FaFileSignature },
			7: { status: 'Complete', description: 'Job is done, but internally accounting is not yet complete', icon: FaCheck },
			8: { status: 'Complete', description: 'Job is complete in every respect except (possibly) reception of payment', icon: FaCheck },
			9: { status: 'Complete', description: 'Job is flagged for deletion', icon: FaCheck },
			10: { status: 'Unknown', description: 'Job is in "Other" status, meaning no standard category applies', icon: FaQuestion },
		};

		const jobStatus = statuses[status.job_status] ? statuses[status.job_status] : false;

		if (business.show_history === 1 && status.job_history) {
			return (
				<Fragment>
					<Header head="Job status" subHead="We found the following information for you" />
					<div>
						<Row>
							<Col>
								<Row>
									<Col>
										<BadgeThing label="Account" content={status.job_title}/>
									</Col>
									<Col>
										<BadgeThing label="Last updated" content={status.when_updated}/>
									</Col>
								</Row>
								<Row>
									<Col>
										<BadgeThing label="Invoice number" content={status.invoice_number}/>
									</Col>
									<Col>
										<BadgeThing label="P.O. number" content={status.purchase_order_number ? status.purchase_order_number : 'None'}/>
									</Col>
								</Row>
								<Row>
									<Col>
										<BadgeThing label="Pending appointment" content={status.pending_appointment ? status.pending_appointment : 'None'}/>
									</Col>
									<Col>
										<BadgeThing label="Status" content={jobStatus ? jobStatus.description : 'Unknown'}/>
									</Col>
								</Row>
							</Col>
							<Col md="8">
								<span className="narrativeHistoryHeader">Narrative history</span>
								<div className="jobHistoryDiv">
									<ListGroup>
										{ this.renderNarrativeHistory() }
									</ListGroup>
								</div>
							</Col>
						</Row>
					</div>
				</Fragment>
			);
		} else {
			const Icon = jobStatus ? jobStatus.icon : FaQuestion;
			return (
				<Fragment>
					<Header head="Job status" subHead="We found the following information for you" />
					<div>
						<Row>
							<Col md="6" className="text-center">
								<div className="mt-3">
									<h2 className="iconColor" style={ { color: business?.cyberSettings?.foreground_color || '' } }><Icon /></h2>
									<span className="jobStatusText">{ jobStatus ? jobStatus.status : 'Unknown' }</span>
									<p className="jobStatusDescription">{ jobStatus ? jobStatus.description : '' }</p>
								</div>
							</Col>
							<Col>
								<Row>
									<Col>
										<BadgeThing label="Account" content={status.job_title}/>
									</Col>
									<Col>
										<BadgeThing label="Last updated" content={status.when_updated}/>
									</Col>
								</Row>
								<Row>
									<Col>
										<BadgeThing label="Invoice number" content={status.invoice_number}/>
									</Col>
									<Col>
										<BadgeThing label="P.O. number" content={status.purchase_order_number ? status.purchase_order_number : 'None'}/>
									</Col>
								</Row>
								<Row>
									<Col>
										<BadgeThing label="Pending appointment" content={status.pending_appointment ? status.pending_appointment : 'None'}/>
									</Col>
								</Row>
							</Col>
						</Row>
					</div>
				</Fragment>
			);
		}
	}
}

export default Status;
