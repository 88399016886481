import React, { Component, Fragment } from 'react';
import { Col, Form } from 'react-bootstrap';
import Header from '../../../global_components/Header';
import RequiredLabel from '../../../global_components/RequiredLabel';
import { getValueFromInputData } from '../../../helpers/objects';
import { formatTimeFrameReadable } from '../../../helpers/formatting';
import Flatpickr from 'react-flatpickr';

export class Reschedule extends Component {
	usableDateFromFlatpickr(date) {
		const { DateTime: dt } = require('luxon');
		const dateTime = dt.fromISO(date.toISOString());
		return dateTime.toISODate();
	}
	constructor(props) {
		super(props);
		this.dateInput = React.createRef();
	}
	componentDidMount() {
		this.dateInput?.current?.node?.focus();
	}
	renderTimeFrames = () => {
		const { business } = this.props;
		const { frames } = business;
		return frames.map((frame, i) => {
			return <option value={ frame.TimeFrame } key={ i }>{ formatTimeFrameReadable(frame.TimeFrame, 'text') }</option>;
		});
	};

	render() {
		const { inputData, business } = this.props;
		if (business.offer_time_frames === 1) {
			return (
				<Fragment>
					<Header head="Reschedule your appointment" subHead="Okay, another time then. When works best for you?" />
					<div>
						<Form.Row>
							<Col>
								<Form.Label className="label">Date <RequiredLabel /></Form.Label>
								<Flatpickr
									placeholder="Click to select"
									className="form-control form-control-sm"
									onChange= { date => { this.props.inputChange({ otherValue: this.usableDateFromFlatpickr(date[0]), otherName: 'reschedule-date' }); } }
									options={
										{
											enable: business.availability_for_zip,
											dateFormat: 'm-d-Y',
										}
									}
									ref={c => (this.dateInput = c) }
								/>
							</Col>
							<Col>
								<Form.Label className="label">Time frame <RequiredLabel /></Form.Label>
								<Form.Control size="sm" as="select" defaultValue={ getValueFromInputData(inputData, 'reschedule-time') } name="reschedule-time" onChange={ (event) => { this.props.inputChange({ event }); } }>
									<option disabled value="default">Select</option>
									{ this.renderTimeFrames() }
								</Form.Control>
							</Col>
						</Form.Row>
						<Form.Row>
							<Col>
								<Form.Label className="label">First and last name <RequiredLabel /></Form.Label>
								<Form.Control size="sm" defaultValue={ getValueFromInputData(inputData, 'reschedule-name') } name="reschedule-name" onChange={ (event) => { this.props.inputChange({ event }); } }></Form.Control>
							</Col>
						</Form.Row>

					</div>
				</Fragment>
			);
		} else {
			return (
				<Fragment>
					<Header head="Reschedule your appointment" subHead="Okay, another time then. When works best for you?" />
					<div>
						<Form.Row>
							<Col>
								<Form.Label className="label">Date <RequiredLabel /></Form.Label>
								<Flatpickr
									placeholder="Click to select"
									className="form-control form-control-sm"
									onChange={ date => { this.props.inputChange({ otherValue: this.usableDateFromFlatpickr(date[0]), otherName: 'reschedule-date' }); } }
									options={
										{
											enable: business.availability_for_zip,
											dateFormat: 'm-d-Y',
										}
									}
									ref={ this.dateInput }
								/>
							</Col>
							<Col>
								<Form.Label className="label">First and last name <RequiredLabel /></Form.Label>
								<Form.Control size="sm" defaultValue={ getValueFromInputData(inputData, 'reschedule-name') } name="reschedule-name" onChange={ (event) => { this.props.inputChange({ event }); } }></Form.Control>
							</Col>
						</Form.Row>
					</div>
				</Fragment>
			);

		}
	}
}

export default Reschedule;
