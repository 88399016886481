import React, { Component, Fragment } from 'react';
import { formatTimeFrameReadable, removeZoneFromAddress } from '../../../helpers/formatting';
import Header from '../../../global_components/Header';
import { FaCalendarAlt, FaCalendarCheck, FaCalendarTimes } from 'react-icons/fa';
import PrettyBox from '../../../global_components/PrettyBox';

export class Initial extends Component {
	figureButtonsAndGridClass = ( confirmation, business ) => {
		const buttons = {
			confirm: <PrettyBox action={ () => this.props.stepForward('confirmation') } head="Confirm" Icon={ FaCalendarCheck } color={ '#198754' } key="confirm" />,
			reschedule: <PrettyBox action={ () => this.props.stepForward('reschedule') } head="Reschedule" Icon={ FaCalendarAlt } color={ '' } key="reschedule" />,
			cancel: <PrettyBox action={ () => this.props.stepForward('cancellation') } head="Cancel" Icon={ FaCalendarTimes } color={ '#dc3644' } key="cancel" />
		};
		let buttonsToRender = [
			buttons['confirm']
		];
		if (!confirmation?.reschedule_forbidden && business?.availability_for_zip?.length > 0) {
			buttonsToRender.push(buttons['reschedule']);
		}
		if (business.allow_cancellation) {
			buttonsToRender.push(buttons['cancel']);
		}
		function renderButtons() {
			return buttonsToRender.map(button => {
				return button;
			});
		}
		return (
			{
				renderButtons: renderButtons,
				gridClassName: `confirmation-initial-columns-${buttonsToRender?.length}`,
			}
		);
	};

	render() {
		const { confirmation, business } = this.props;
		const usables = this.figureButtonsAndGridClass(confirmation, business);
		return (
			<Fragment>
				<Header head="Confirm your appointment" subHead={ 'We found the following appointment for you. What would you like to do?' } />
				<div className={ usables.gridClassName }>
					<div className="">
						<b>Date: </b>
						{ confirmation.time.appointment_description }<br />
						<b>Time frame: </b>{ formatTimeFrameReadable(confirmation.time.target_time, 'description') }<br />
						<b>Location: </b><br />
						{ confirmation.location.name }<br />
						{ removeZoneFromAddress(confirmation.location.address) }<br />
						{ removeZoneFromAddress(confirmation.location.address2) }<br />
					</div>
					<Fragment>
						{ usables.renderButtons() }
					</Fragment>


				</div>


			</Fragment>
		);
	}
}

export default Initial;
