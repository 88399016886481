import React, { Component, Fragment } from 'react';
import { Alert } from 'react-bootstrap';
import { FaExclamationCircle } from 'react-icons/fa';
import Loader from '../../global_components/Loader';
import apiRequest from '../../helpers/data';
import { getZipPostalFromConfirmation } from '../../helpers/objects';
import Initial from './steps/Initial';
import Confirmation from './steps/Confirmation';
import Reschedule from './steps/Reschedule';
import Confirmed from './steps/Confirmed';
import Rescheduled from './steps/Rescheduled';
import { indexOf } from 'lodash';
import { validateText, validateTime, validateDate } from '../../helpers/validation';
import _ from 'lodash';
import GenericError from './steps/GenericError';
import Cancellation from './steps/Cancellation';
import Cancelled from './steps/Cancelled';
import Navigation from '../../global_components/Navigation';
import { formatPhoneReadable } from '../../helpers/formatting';
import { DateTime as LuxonDate } from 'luxon';

export class Confirm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			error: null,
			stepIsValid: true,
			step: 'initial',
			stepOrder: ['initial'],
			scenarios: {
				initial: ['initial'],
				confirmation_adds: ['confirmation', 'final_confirmation'],
				reschedule_adds: ['reschedule', 'final_reschedule'],
				cancellation_adds: ['cancellation', 'final_cancellation'],
			},
			rescheduleAllowed: true,
			inputData: {
				confirmation: {
					name: '',
				},
				reschedule: {
					date: '',
					time: 'default',
					name: '',
				},
				cancellation: {
					name: '',
				}
			},
			validations: {
				text: validateText,
				time: validateTime,
				date: validateDate,
			},
			stepMap: {
				initial: {
					component_name: Initial,
					navigation_state: 'none',
				},
				confirmation: {
					component_name: Confirmation,
					navigation_state: 'confirm-final',
					requirements: [
						{ name: 'confirmation-name', type: 'text' }
					],
					dependent_function: this.processConfirmation
				},
				reschedule: {
					component_name: Reschedule,
					navigation_state: 'reschedule-final',
					requirements: [
						{ name: 'reschedule-date', type: 'date' },
						{ name: 'reschedule-name', type: 'text' },
						{ name: 'reschedule-time', type: 'text' },
					],
					dependent_function: this.processReschedule
				},
				cancellation: {
					component_name: Cancellation,
					navigation_state: 'cancel-final',
					requirements: [
						{ name: 'cancellation-name', type: 'text' },
					],
					dependent_function: this.processCancellation
				},
				generic_error: {
					component_name: GenericError,
					navigation_state: 'none',
				},
				final_confirmation: {
					component_name: Confirmed,
					navigation_state: 'none',
				},
				final_reschedule: {
					component_name: Rescheduled,
					navigation_state: 'none',
				},
				final_cancellation: {
					component_name: Cancelled,
					navigation_state: 'none',
				},
			},
		};
	}

	componentDidMount() {
		this.getConfirmation();
	}

	processConfirmation = async () => {
		return new Promise(async (resolve) => {
			const { confirmation, inputData } = this.state;
			const confirmationResponse = await apiRequest({ endpoint: 'v3/updateAppointmentConfirmation', parameters: { business_id: confirmation.client_id, schedule_item_id: confirmation.schedule_item_id, is_confirmed: 1, person_who_connected: inputData.confirmation.name } });
			resolve(confirmationResponse);
		});
	};

	processCancellation = async () => {
		return new Promise(async (resolve) => {
			const { confirmation, inputData } = this.state;
			const cancellationResponse = await apiRequest({ endpoint: 'v3/updateAppointmentConfirmation', parameters: { business_id: confirmation.client_id, schedule_item_id: confirmation.schedule_item_id, is_confirmed: 3, person_who_connected: inputData.cancellation.name } });
			resolve(cancellationResponse);
		});
	};

	processReschedule = async () => {
		return new Promise(async (resolve) => {
			const { confirmation, inputData, business } = this.state;
			const rescheduleResponse = await apiRequest({ endpoint: 'v3/updateAppointmentConfirmation', parameters: {
				business_id: confirmation.client_id,
				schedule_item_id: confirmation.schedule_item_id,
				is_confirmed: 0,
				person_who_connected: inputData.reschedule.name,
				new_appointment: inputData.reschedule.date,
				new_timeframe: business.offer_time_frames === 1 ? inputData.reschedule.time : '!',
			} });
			resolve(rescheduleResponse);
		});
	};

	getConfirmation = async () => {
		const { forwardParam: scheduleItemId, business, globalError } = this.props;
		try {
			let [frames_response, confirmation_response] = await Promise.all([
				apiRequest({ endpoint: 'v3/publicTimeFrames', parameters: { business_id: business.client_id } }),
				apiRequest({ endpoint: 'v3/publicAppointmentConfirmation', parameters: { business_id: business.client_id, schedule_item_id: scheduleItemId } }),
			]);
			const { stepMap } = this.state;
			const { data: frames } = frames_response;
			business.frames = frames;
			const { data: confirmationArray } = confirmation_response;
			if (business.offer_time_frames !== 1) {
				stepMap.reschedule.requirements.splice(2,1);
			}
			document.title = `${business.client_name} - Confirm appointment`;
			if (confirmationArray) { // if confirmation exists
				const confirmation = confirmationArray[0];
				const dateTimeCenter = LuxonDate.fromISO(confirmation.time.center_timestamp.replace(' ', 'T'));
				const dateTimeNow = LuxonDate.local();
				if (confirmation.is_confirmed || ( confirmation.time.new_appointment && confirmation.time.new_timeframe)) { // if IS already confirmed or rescheduled
					globalError(`This appointment has already been confirmed, rescheduled or cancelled. If you want to make further changes, please request a new link from ${ this.props.business.client_name }.`);
					return false;
				} else if (dateTimeNow > dateTimeCenter) { // if appointment date/time has already passed
					globalError(`We can no longer allow you to confirm or reschedule your appointment because the confirmation window has passed. Please contact the office if you must make last-minute changes at ${ formatPhoneReadable(business?.client_phone) }.`);
					return false;
				} else { // if is not already confirmed or rescheduled
					if (confirmation.reschedule_forbidden || business?.cyberSettings?.disallow_reschedule) {
						this.setState({ stepMap: stepMap, confirmation: confirmation, business: business, loading: false });
					} else {
						const isCanadian = business.is_canadian === 0 ? false : true;
						const zipPostal = getZipPostalFromConfirmation(confirmation, isCanadian);
						const department = confirmation.department;
						const availability = await apiRequest({ endpoint: 'v3/publicAvailabilityByZip', parameters: { business_id: business.client_id, zip_code: zipPostal, department: department, omit_zones_list: business.cyberSettings?.omit_zones ? business.cyberSettings?.omit_zones_list : '' } });
						const limit_scheduling_window = business?.cyberSettings?.limit_scheduling_window;

						if (availability.length < 1 || availability.message === 'no zones') { // if no dates for reschedule are available
							const { stepMap } = this.state;
							this.setState({ stepMap: stepMap, confirmation: confirmation, business: business, loading: false });
						} else {
							var availableDates = [];
							var now = LuxonDate.now();
							for (let i = 0; i < availability['data'].length; i++) {
								var date = LuxonDate.fromISO(availability['data'][i]['AvlblDt']);
								if ((availability['data'][i]['SlotsAvailable'] > 0 ) && (date >= now)) {
									if (limit_scheduling_window > 0 && date > now.plus({ weeks: limit_scheduling_window })) {
										continue;
									}
									availableDates.push(availability['data'][i]['AvlblDt'].substring(0,10));
								}
							}
							business.availability_for_zip = availableDates;
							this.setState({ confirmation: confirmation, business: business, loading: false });
						}
					}
				}
			} else { // if no confirmations are found
				globalError('We could not find any records given the information provided. This could be because your confirmation link expired or your appointment date already passed.');
				return false;
			}
		} catch (err) {
			globalError(`Failed to get confirmation data. Please contact Rossware at ${ formatPhoneReadable(process.env.REACT_APP_ROSSSWARE_PHONE) } and provide the below error.`, err.toString());
		}
	};

	renderContentsByStep(step) {
		const { error } = this.state;
		const { component_name: ComponentName, navigation_state } = this.state.stepMap[step];
		const { business, confirmation, inputData } = this.state;
		return (
			<Fragment>
				<ComponentName business={ business } inputData={ inputData } confirmation={ confirmation } inputChange={ this.inputChange } stepForward={ this.stepForward } />
				{ error && <Alert className="mx-3" variant="warning"><FaExclamationCircle className="errorIcon"/>&nbsp;&nbsp;{error}</Alert> }
				<Navigation displayMode={navigation_state} stepForward={ this.stepForward } stepBack={ this.stepBack } validated={ this.state.stepIsValid } business={ business } />
			</Fragment>
		);
	}

	inputChange = ({ event, otherValue, otherName }) => {
		const { inputData } = this.state;
		var name = event ? event.target.name : false;
		var value = event ? event.target.value : false;
		const useValue = otherValue || value;
		const useName = otherName || name;
		const keyArray = useName.includes('-') ? useName.split('-') : [useName];
		var newObject = {};
		if (keyArray.length === 1) {
			newObject[keyArray[0]] = useValue;
		} else if (keyArray.length === 2) {
			newObject[keyArray[0]] = {};
			newObject[keyArray[0]][keyArray[1]] = useValue;
		}
		const newInputData = _.merge(inputData, newObject);
		this.setState({ inputData: newInputData, stepIsValid: this.validate() });
	};

	stepForward = (andAddStepsFor) => {
		const { step, stepOrder, business, stepIsValid } = this.state;
		if (!stepIsValid) { return false; }
		const { initial } = this.state.scenarios;
		var newStepOrder;
		var addedSteps;
		if (andAddStepsFor) {
			const addedStepsKey = `${andAddStepsFor}_adds`;
			addedSteps = this.state.scenarios[addedStepsKey];
			newStepOrder = initial.concat(addedSteps);
			this.setState({ stepOrder: newStepOrder, business: business });
		}
		const relevantStepOrder = newStepOrder || stepOrder;
		const currentStepNumber = indexOf(relevantStepOrder, step);
		const targetStepName = relevantStepOrder[currentStepNumber + 1];
		const dependentFunction = this.state.stepMap[step].dependent_function || false;
		if (dependentFunction) {
			this.setState({ loading: true });
			dependentFunction().then(() => {
				this.setState({ step: targetStepName, stepIsValid: this.validate(targetStepName), error: null, loading: false });
			})
				.catch((err) => {
					this.setState({ error: err, loading: false });
				});
		} else {
			this.setState({ step: targetStepName, stepIsValid: this.validate(targetStepName), error: null, loading: false });
		}
	};
	stepBack = () => {
		const { step, stepOrder } = this.state;
		const currentStepNumber = indexOf(stepOrder, step);
		const targetStepName = stepOrder[currentStepNumber - 1];
		this.setState({ step: targetStepName, stepIsValid: this.validate(targetStepName), error: null });
	};

	validate = (specifiedStep) => {
		const { inputData, validations, stepMap } = this.state;
		const step = specifiedStep || this.state.step;
		const stepRequirements = stepMap[step].requirements;
		if (!stepRequirements) return true;
		var valid = true;
		for (var stepRequirement of stepRequirements) {
			const { name, type } = stepRequirement;
			const keyArray = name.includes('-') ? name.split('-') : [name];
			var valueToCheck;
			if (keyArray.length === 1) {
				valueToCheck = inputData[keyArray[0]];
			} else if (keyArray.length === 2) {
				valueToCheck = inputData[keyArray[0]][keyArray[1]];
			}
			const validation = validations[type];
			if (!validation(valueToCheck)) {
				valid = false;
				break;
			}
		}
		return valid;
	};

	render() {
		const { loading, step } = this.state;
		if (loading) {
			return (
				<Loader />
			);
		} else {
			return (
				<Fragment>
					{ this.renderContentsByStep(step) }
				</Fragment>
			);
		}
	}
}

export default Confirm;
