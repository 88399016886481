import React, { Component } from 'react';

export class PrettyBox extends Component {
	render() {
		const { head, subHead, Icon, action, color } = this.props;
		return (
			<div onClick={ () => { action(); } } className="prettyBox">
				<div>
					<span className="prettyBoxIcon" style={ { color: color || '#007bff' } }><Icon /></span>
					<div>{ head }</div>
					{subHead && <p>{ subHead }</p> }
				</div>
			</div>
		);
	}
}

export default PrettyBox;