import React, { Fragment } from 'react';
import apiRequest from '../helpers/data';
import transformBusinessToUnderscore from '../helpers/objects';
import { withRouter } from 'react-router';
import BrandedFooter from '../global_components/BrandedFooter';
import BrandedHeader from '../global_components/BrandedHeader';
import Loader from '../global_components/Loader';
import { FaExclamationCircle } from 'react-icons/fa';

class Go extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			notAllowed: false,
			business: null,
			globalError: null,
		};
	}

	async componentDidMount() {
		const { businessParam } = this.props.match.params;
		const business = await this.getBusiness({ businessParam });
		if (!business || business.cyber_office_allowed !== 3) {
			this.globalError('This business is not currently subscribed to SD-CyberOffice. If you are the business owner, please contact Rossware to sign up!');
			return false;
		}
		this.setState({ loading: false, business });
	}

	getBusiness = async({ businessParam }) => {
		const business_response = await apiRequest({ endpoint: 'v3/publicBusiness', parameters: { business_id: businessParam } });
		console.log(business_response);
		const cyber_settings_response = await apiRequest({ endpoint: 'v3/publicCyberTheme', parameters: { business_id: businessParam } });

		if (!business_response?.data || !cyber_settings_response?.data) {
			return false;
		}
		const business = transformBusinessToUnderscore(business_response);
		const cyber_settings = cyber_settings_response?.data;
		business.cyberSettings = cyber_settings;
		return business;
	};

	// this function is passed into whichever "tool" is being used so it can also throw a global error
	globalError = (error, errorFromCode) => {
		this.setState({ globalError: { error, errorFromCode }, loading: false });
	};

	render() {
		const { forwardParam } = this.props.match.params;
		const { framed, tool: Tool, toolName } = this.props;
		const { loading, business, globalError } = this.state;

		if (loading) {
			return (
				<div className="mainContainer">
					<Loader />
				</div>
			);
		}
		if (globalError) {
			const { business } = this.state;
			return (
				<div className="mainContainer">
					<div className="text-center">
						<h1 className="text-primary"><FaExclamationCircle style={{ color: business?.cyberSettings?.foreground_color || '' }} /></h1>
						<p>{ globalError?.error }</p>
						{ globalError?.errorFromCode &&
							<Fragment>
								See error below:
								<div style={{ backgroundColor: '#eeeeee', color: 'red', padding: '10px', marginTop: '10px', textAlign: 'left' }}>
									{ globalError?.errorFromCode }
								</div>
							</Fragment>
						}
					</div>
				</div>
			);
		}
		if (framed) {
			return (
				<div className="">
					{ (!!business?.cyberSettings?.show_header_footer && !framed) && <BrandedHeader business={ business } toolName={ toolName }/> }
					<div className={ framed ? 'mainContainer mainContainerFramed' : 'mainContainer' }>
						<Tool forwardParam={ forwardParam } business={ business } framed={ framed } globalError={ this.globalError } />
					</div>

					{ (!!business?.cyberSettings?.show_header_footer && !framed && business?.cyberSettings?.back_to_website_url) && <BrandedFooter business={ business }/> }
				</div>
			);
		} else {
			return (
				<div className="greyBackdropOrNo">
					{ (!!business?.cyberSettings?.show_header_footer && !framed) && <BrandedHeader business={ business } toolName={ toolName }/> }
					<div className={ framed ? 'mainContainer mainContainerFramed' : 'mainContainer' }>
						<div className="stepContainer">
							<Tool forwardParam={ forwardParam } business={ business } framed={ framed } globalError={ this.globalError } />
						</div>
						{/* <div className="privacyPolicy" onClick={ () => { window.open('https://www.rossware.com/privacy-policy') } }>Privacy Policy</div> */}
					</div>

					{ (!!business?.cyberSettings?.show_header_footer && !framed && business?.cyberSettings?.back_to_website_url) && <BrandedFooter business={ business }/> }
				</div>
			);
		}
	}
}

export default withRouter(Go);