import React, { Component } from 'react';
import { FaExclamationCircle } from 'react-icons/fa';

export class GenericError extends Component {
	render() {
		return (
			<div className="text-center">
				<h1 className="text-primary"><FaExclamationCircle /></h1>
				<p>Uh oh! Something went wrong. Please contact Rossware at 800-353-4101.</p>
			</div>
		);
	}
}

export default GenericError;
