import React, { Component, Fragment } from 'react';
import { Form } from 'react-bootstrap';
import Header from '../../../global_components/Header';
import { getValueFromInputData } from '../../../helpers/objects';

export class CustomTerms extends Component {

	render() {
		const { inputData, business, inputChange, scenario } = this.props;
		const useTerms = ['regular_schedule', 'third_party'].includes(scenario) ? business?.cyberSettings?.custom_cod_terms : business?.cyberSettings?.custom_warranty_terms;
		return (
			<Fragment>
				<Header head="Terms and Conditions" subHead="We kindly ask you review and agree to our terms of service before continuing." />
				<div className="customTermsCard">{ useTerms }</div>
				<div className="customTermsCheckContainer">
					<Form.Check type="checkbox" id="customTerms" defaultChecked={ getValueFromInputData(inputData, 'appointment-agreed_to_terms') } name="appointment-agreed_to_terms" onChange={ (event) => { inputChange({ event }); } } />
					<label htmlFor="customTerms">I agree</label>
				</div>
			</Fragment>
		);
	}
}

export default CustomTerms;
