import React, { Component } from 'react';

export class NoDirectSchedule extends Component {
	render() {
		return (
			<div className="d-flex justify-content-center">
				<p>It seems you have reached this page by accident. To book an appointment, <a href="https://fredsappliance.com/service/request-service/">click here.</a> If you are looking to confirm your appointment, please chat, text or call us.</p>
			</div>
		);
	}
}

export default NoDirectSchedule;
