import React, { Component, Fragment } from 'react';
import { FaHandshake, FaMicrophoneAlt } from 'react-icons/fa';
import Header from '../../../global_components/Header';
import PrettyBox from '../../../global_components/PrettyBox';

export class OfferVirtualMeeting extends Component {
	render() {
		const { business } = this.props;
		return (
			<Fragment>
				<Header head="How would you like to conduct the appointment?" subHead="We offer virtual appointments for cases in which a physical visit is not required. Would you like to meet with us virtually or is an in-person appointment required?" />
				<div className="grid-2-col">
					<PrettyBox action={ () => {
						this.props.inputChange({ otherValue: false, otherName: 'appointment-virtual' });
						this.props.stepForward();
					} } head="Standard" subHead="A technician will come to your location to complete service." Icon={ FaHandshake } color={ business?.cyberSettings?.foreground_color } />
					<PrettyBox action={ () => {
						this.props.inputChange({ otherValue: true, otherName: 'appointment-virtual' });
						this.props.stepForward();
					} } head="Virtual" subHead="No in-person visit is required so the appointment will be conducted virtually." Icon={ FaMicrophoneAlt } color={ business?.cyberSettings?.foreground_color } />
				</div>
			</Fragment>
		);
	}
}

export default OfferVirtualMeeting;

