import React, { Component } from 'react';

export class Header extends Component {
	render() {
		if (this.props.subHead) {
			return (
				<div className="w-100 text-center stepHeader">
					<h5>{ this.props.head }</h5>
					<span className="textLight">{ this.props.subHead }</span>
				</div>
			);
		} else {
			return (
				<div className="w-100 text-center stepHeader">
					<h5>{ this.props.head }</h5>
				</div>
			);
		}
	}
}

export default Header;
