import React, { Component } from 'react';
import { FaToolbox } from 'react-icons/fa';

export class Initial extends Component {
	render() {
		const { business } = this.props;
		return (
			<div className="text-center">
				<h1 className="iconColor" style={ { color: business?.cyberSettings?.foreground_color || '' } }><FaToolbox /></h1>
			</div>
		);
	}
}

export default Initial;