import React, { Component, Fragment } from 'react';
import { FaBuilding, FaShieldAlt, FaUserFriends, FaUser } from 'react-icons/fa';
import Header from '../../../global_components/Header';
import PrettyBox from '../../../global_components/PrettyBox';

export class PaymentStructure extends Component {
	render() {
		const { business } = this.props;
		return (
			<Fragment>
				<Header head="Who is responsible for payment?" />
				<div className="grid-4-col">
					<PrettyBox action={ () => this.props.stepForward('regular_schedule') } head="Standard" subHead="You are solely responsible for payment on this appointment." Icon={ FaUser } color={ business?.cyberSettings?.foreground_color } />
					<PrettyBox action={ () => this.props.stepForward('original_warranty') } head="Manufacturer Warranty" subHead="This work will be covered by the product's manufacturer." Icon={ FaBuilding } color={ business?.cyberSettings?.foreground_color } />
					<PrettyBox action={ () => this.props.stepForward('extended_warranty') } head="Extended Warranty" subHead="You purchased an extended warranty which covers the cost of this appointment." Icon={ FaShieldAlt } color={ business?.cyberSettings?.foreground_color } />
					<PrettyBox action={ () => this.props.stepForward('third_party') } head="Third-Party" subHead="Someone other than you is responsible for payment on this appointment." Icon={ FaUserFriends } color={ business?.cyberSettings?.foreground_color } />
				</div>
			</Fragment>
		);
	}
}

export default PaymentStructure;